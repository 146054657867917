import { Box, Tab, } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useHistory, useLocation } from 'react-router-dom';


import { BILLING_TABS } from "../../../constants/constatnt-variable";
import PaymentSetup from "./payment-setup";
import CurrentInvoice from "./current-invoice"
import PastInvoice from "./past-invoice";
import { BillContextProvider } from "./BillContext";
import { BillContext } from "./BillContext";




export default function Billing() {

    const [userCode, setUserCode] = useState('');
    useEffect(() => {

        const fetchUserCode = () => {
            const localStorageData = localStorage.getItem('cliqloan-user-app-session');
            if (localStorageData) {
                const parsedData = JSON.parse(localStorageData);
                const code = parsedData?.data?.userCode;
                setUserCode(code);
            }
        };

        fetchUserCode();


    }, []);





    const [returnedFromPayment, setReturnedFromPayment] = useState(false);

    const [value, setValue] = useState(() => {
        return BILLING_TABS.PaymentSetup;
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [currentInvoiceData, setCurrentInvoiceData] = useState([]);
    const [pastInvoiceData, setPastInvoiceData] = useState([]);




    return (
        <BillContextProvider>
            <Box>

                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" allowScrollButtonsMobile>
                            <Tab label="Payment Setup" value={BILLING_TABS.PaymentSetup} />
                            <Tab label="Current Invoice" value={BILLING_TABS.CurrentInvoice} />
                            <Tab label="Past Invoice" value={BILLING_TABS.PastInvoice} />
                        </TabList>
                    </Box>
                    <TabPanel value={BILLING_TABS.PaymentSetup} sx={{ px: { xs: 0, md: 3 } }}>
                        {/* Pass activePaymentMethodTab and setActivePaymentMethodTab to PaymentSetup */}
                        <PaymentSetup
                            returnedFromPayment={returnedFromPayment}
                            setReturnedFromPayment={setReturnedFromPayment}
                        // paymentStatus={paymentStatus}
                        />
                    </TabPanel>
                    <TabPanel value={BILLING_TABS.CurrentInvoice} sx={{ px: { xs: 0, md: 3 } }}>
                        {/* Pass currentInvoiceData to the Current Invoice component */}
                        <CurrentInvoice userCode={userCode} />
                    </TabPanel>
                    <TabPanel value={BILLING_TABS.PastInvoice} sx={{ px: { xs: 0, md: 3 } }}>
                        {/* Pass pastInvoiceData to the Past Invoice component */}
                        <PastInvoice />
                    </TabPanel>
                </TabContext>

            </Box>
        </BillContextProvider>
    );
}



