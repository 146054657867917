// import { Box, Button, FormLabel, IconButton, Stack, Typography } from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import {

//     BORROWER_ONLY_STATUS,

// } from '../../constants/constatnt-variable'



// import { Controller, useForm, useWatch } from 'react-hook-form'

// import RadioButtonGroupDoc from '../../helpers/radio-button-group-doc'
// import { LoanDocumentValidation, TaskTypeValidation } from '../../constants/validation-rules'


// import MultiSelectNotAllDropDown from '../../helpers/dropdown/multi-select-noall-dropdown'




// const LinkDocumentConfirm = ({ handleClose, onSubmit, editItem, linkDocumentDetails }) => {
//     const { control, handleSubmit, setValue, formState: { errors } } = useForm({
//         defaultValues: {
//             borrowerOnlyDocument: editItem?.borrowerOnlyDocument,
//             visibility: [
//                 editItem?.borrowerVisibility ? 'Borrower' : '',
//                 editItem?.realtorsVisibility ? 'Realtors' : ''
//             ].filter(Boolean),
//         }
//     });

//     return (

//         <>

//             <Typography>Link {linkDocumentDetails?.documentType}to the Loan Documents.
//             </Typography>

//             <form onSubmit={handleSubmit(onSubmit)}>
//                 <Box width={'100%'} my={3}>
//                     <Box>

//                         <Controller
//                             name="visibility"
//                             control={control}
//                             rules={LoanDocumentValidation.visibility}
//                             required
//                             render={({ field }) => (
//                                 <MultiSelectNotAllDropDown
//                                     field={{
//                                         ...field,
//                                         value: field.value || [],
//                                     }}
//                                     options={["Borrower", "Realtors"]}
//                                     errors={errors.visibility}
//                                 />
//                             )}
//                         />


//                         <Controller
//                             name="borrowerOnlyDocument"
//                             control={control}
//                             rules={LoanDocumentValidation.borrowerOnlyDocument}
//                             render={({ field }) => (
//                                 <RadioButtonGroupDoc
//                                     {...field}
//                                     field={field}
//                                     required
//                                     direction="row"
//                                     labelComponent={<FormLabel>{`Is this a Borrower Document?`}</FormLabel>}
//                                     data={BORROWER_ONLY_STATUS}
//                                     error={errors?.borrowerOnlyDocument?.message}
//                                 />
//                             )}
//                         />
//                     </Box>


//                     <Box marginY={4} textAlign={'right'}>
//                         <Stack direction={"row"} gap={1} alignItems={"center"} my={2} justifyContent={"space-between"}>
//                             <Button type="button" onClick={handleClose} sx={{ width: '50%' }}>
//                                 Cancel
//                             </Button>
//                             <Button type="submit" variant="contained" sx={{ width: '50%' }}>
//                                 SAVE
//                             </Button>
//                         </Stack>
//                     </Box>
//                 </Box>
//             </form>
//         </>
//     );
// };

// export default LinkDocumentConfirm;


import { Box, Button, FormLabel, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
    BORROWER_ONLY_STATUS,
} from '../../constants/constatnt-variable';

import RadioButtonGroupDoc from '../../helpers/radio-button-group-doc';
import { LoanDocumentValidation } from '../../constants/validation-rules';
import MultiSelectNotAllDropDown from '../../helpers/dropdown/multi-select-noall-dropdown';

const LinkDocumentConfirm = ({ handleClose, onSubmit, editItem, linkDocumentDetails }) => {
    const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            borrowerOnlyDocument: editItem?.borrowerOnlyDocument ?? false,
            visibility: [
                editItem?.borrowerVisibility ? 'Borrower' : '',
                editItem?.realtorsVisibility ? 'Realtors' : ''
            ].filter(Boolean),
        }
    });

    // Watch for changes to the 'visibility' field
    const visibility = useWatch({
        control,
        name: 'visibility',
        defaultValue: [],
    });

    useEffect(() => {
        if (visibility.includes("Borrower")) {
            setValue('borrowerOnlyDocument', false);

        }
    }, [visibility, setValue, getValues]);



    return (
        <>
            <Typography>Link {linkDocumentDetails?.documentType} to the Loan Documents.</Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Box width={'100%'} my={3}>
                    <Box>
                        <Controller
                            name="visibility"
                            control={control}
                            // rules={LoanDocumentValidation.visibility}

                            render={({ field }) => (
                                <MultiSelectNotAllDropDown
                                    label={"Visibility To"}
                                    field={{
                                        ...field,
                                        value: field.value || [],
                                    }}
                                    options={["Borrower", "Realtors"]}
                                    errors={errors.visibility}
                                />
                            )}
                        />

                        {/* Conditionally render the borrowerOnlyDocument field */}
                        {visibility.includes("Borrower") && (
                            <Controller
                                name="borrowerOnlyDocument"
                                control={control}
                                rules={LoanDocumentValidation.borrowerOnlyDocument}
                                render={({ field }) => (
                                    <RadioButtonGroupDoc
                                        {...field}
                                        field={field}
                                        required
                                        direction="row"
                                        labelComponent={<FormLabel>{`Is this a Borrower Document?`}</FormLabel>}
                                        data={BORROWER_ONLY_STATUS}
                                        error={errors?.borrowerOnlyDocument?.message}
                                    />
                                )}
                            />
                        )}
                    </Box>

                    <Box marginY={4} textAlign={'right'}>
                        <Stack direction={"row"} gap={1} alignItems={"center"} my={2} justifyContent={"space-between"}>
                            <Button type="button" onClick={handleClose} sx={{ width: '50%' }}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" sx={{ width: '50%' }}>
                                SAVE
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default LinkDocumentConfirm;
