import { Button, Grid, Box, Typography, Stack, useMediaQuery, Alert } from "@mui/material";
import React, { useContext, useState } from "react";
import LoanSummaryBorrowerInformation from "./loan-summary-borrower-profile-realtors";
import LoanSummaryMloMlpProfile from "./loan-summary-mlo-mlp-profile.realtors";
import { API } from "../../../../services/api-config";
import { downloadReportFromServer, getImageUrl } from "../../../../helpers/file-upload-download";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import { kstheme } from "../../../../theme/theme";
import { DialogCommonHooks } from '../../../../helpers/modal/modal'

export default function LoanSummaryDetailsLayout({
  loanState,
  loanStateDate,
  lockStatus,
  lockExpirationDate,
  borrowerData,
  mloData,
  mlpData,
  userType,
  mloMlpList,
  loanCode,
  reload,
  subjectProperty
}) {
  //const { visiblity } = useContext(LoanSummaryParentData);
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));





  const [modalOpenBox, setModalOpenBox] = useState(false);
  const [modalOpenBoxMismo, setModalOpenBoxMismo] = useState(false);

  const handleClose = () => {
    setModalOpenBox(false);
    setModalOpenBoxMismo(false)
  };


  const handleModalOpenBox = () => {
    setModalOpenBox(true);
  };


  const handleDownloadConfirmation = () => {
    setModalOpenBox(false);
    handleFullListDocumentExport()
  };


  const handleModalOpenBoxMismo = () => {
    setModalOpenBoxMismo(true);
  };


  const handleDownloadConfirmationMismo = () => {
    setModalOpenBoxMismo(false);
    handleMismoExport()
  };


  return (
    <>
      <Grid
        container
        alignItems={"center"}
        bgcolor={"white"}
        mb={3}
        p={2}
        borderRadius={2}
      >
        {/* Borrower information */}
        <Grid item xs={12} md={6}>
          <LoanSummaryBorrowerInformation
            loanState={loanState}
            loanStateDate={loanStateDate}
            lockStatus={lockStatus}
            lockExpirationDate={lockExpirationDate}
            borrowerData={borrowerData}
            subjectProperty={subjectProperty}
          />
        </Grid>

        {/* MLO/MLP information */}

        <Grid item xs={12} md={6}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid container>

              <Grid item xs={12} mt={1} mb={2}>
                <LoanSummaryMloMlpProfile
                  type="MLO"
                  data={mloData}
                  userType={userType}
                  mloMlpList={mloMlpList}
                  loanCode={loanCode}
                  reload={reload}
                />

              </Grid>
              <Grid item xs={12}>
                <LoanSummaryMloMlpProfile
                  type="MLP"
                  data={mlpData}
                  userType={userType}
                  mloMlpList={mloMlpList}
                  loanCode={loanCode}
                  reload={reload}
                />

              </Grid>

            </Grid>


          </Stack>
        </Grid>


        {/* Loan files and document infromation */}


      </Grid>



    </>


  );
}
