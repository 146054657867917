
import React, { useState } from "react";
import CustomInput from "../../../../helpers/custom-input";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import {
    Box,
    Button,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { uploadFile } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { toastNotify } from "../../../../helpers/alert-msg";

export default function UploadFromLocal({
    formId,
    taskFormData,
    handleClose,
    editItem,
    setLocalData,
    documentTypes
}) {
    const [localFileDetails, setLocalFileDetails] = useState(null);

    const {
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            documentType: editItem?.documentType || '',
            documentName: editItem?.documentName || '',
        },
    });


    const documentType = watch('documentType');
    const documentName = watch('documentName');

    const isUploadDisabled = !documentType && !documentName;


    const fileUpload = async (event) => {
        let file = event.target.files[0];
        if (file) {
            const res = await uploadFile({
                formId: formId,
                docId: taskFormData?.brokerDocId ? taskFormData?.brokerDocId : null,
                file: file,
                serviceType: "Loan",
                path: API.proxyDocumentOthersUpload,
            });

            if (res.status === 201) {
                toastNotify("success", res.data.message);




                setLocalFileDetails(res.data);


                if (documentType || documentName) {
                    const formData = {
                        documentName: documentType ? documentType : documentName,
                        docType: 'LocalUpload',
                        ...res.data,
                    };

                    setLocalData(formData);
                    handleClose();
                } else {
                    toastNotify("error", "Document type or name is missing.");
                }
            } else {
                toastNotify("error", res.data.message);
            }
        } else {
            console.log("not found");
        }
    };

    return (
        <><Box
            sx={{
                minHeight: '70vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Stack spacing={2} sx={{ width: '100%' }}>
                <form>
                    <Controller
                        name='documentType'
                        control={control}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Select Document Type'}
                                required
                                option={documentTypes}
                                error={errors.documentType?.message}

                            />
                        )}
                    />

                    <Box>
                        <Typography sx={{ color: '#D94166' }}>
                            OR
                        </Typography>

                    </Box>

                    <Controller
                        name="documentName"
                        control={control}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                field={field}
                                type={"input"}
                                label={"Enter Document Type"}
                                required
                                error={errors.documentName?.message}
                                placeholder={'Enter your own document type'}
                            />
                        )}
                    />

                    <Button
                        component="label"
                        size="small"
                        startIcon={<KSCloudUploadIcon color={"primary"} />}
                        disabled={isUploadDisabled} // Disable the button based on the condition
                    >
                        <input
                            onChange={fileUpload}
                            hidden
                            // accept="image/*"
                            type="file"
                        />
                        {`Upload`}
                    </Button>


                    {/* <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
                    <Button type="submit" variant="contained">
                        {"Add"}
                    </Button>

                    <Button variant="text" onClick={handleClose}>
                        Cancel
                    </Button>
                </Stack> */}
                </form>
            </Stack>
        </Box>
            <Box>
                <Stack spacing={2}></Stack>
            </Box>
        </>
    );
}
