


import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, IconButton, Tab, Stack, Typography, Divider, Grid, Paper } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import LinkIcon from '@mui/icons-material/Link';

import KSCloudDownloadIcon from '@mui/icons-material/CloudDownload'; // Replace with your actual icon
import { KSDocumentSearchIcon } from '../../icons/custome-icon';
import { docPreviewResolve, docPreviewWithToken, documentPreview } from '../../helpers/file-upload-download';
import { API } from '../../services/api-config';

const RealtorDocumentView = ({
    realtorTaskCount,
    realtorDocuments,
    accessCode,
    taskCode
}) => {
    const [tabValue, setTabValue] = useState('1');

    const [realtorDocs, setRealtorDocs] = useState(realtorDocuments)





    const previewLoanDocument = (row) => {

        const apiUrl = API.proxyDocumentDownloadThirdParty
        const token = accessCode
        let storageKey
        let fileName
        let docId



        const param = {
            storageKey: row?.storageKey,
            // fileName: fileName,
            id: row?.storageDocId,
            thirdPartyTaskCode: taskCode

        }



        docPreviewResolve(apiUrl, param, token)
            .then((url) => {
                console.log('Preview URL:', url);
            })
            .catch((error) => {
                console.error('Error previewing the file:', error);
            });


    }

    return (
        <>
            <Paper sx={{ borderRadius: '8px', padding: 2 }}>



                <Box paddingX={1} bgcolor={"white"} sx={{ width: '100%', typography: 'body1' }}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gap={1}
                    >
                        <Typography variant="h3">{`Documents Related to the Loan`}</Typography>


                    </Stack>
                    <Box my={1}>
                        <Divider />
                    </Box>



                    <Box mt={3} my={1}>


                        <List>
                            {realtorDocuments?.map((item, index) => (


                                <Box mb={2}
                                    key={item.id}
                                    sx={{
                                        border: '1px solid lightGrey',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '8px',
                                    }}
                                >
                                    <ListItem

                                        secondaryAction={
                                            <>


                                                <IconButton
                                                    color="primary"
                                                    onClick={() => previewLoanDocument(item)}  // Pass the row data when clicked
                                                //disabled={!row?.original?.fileName}
                                                >
                                                    <KSDocumentSearchIcon />
                                                </IconButton>

                                            </>
                                        }
                                    >

                                        {/* <ListItemText
                                            primary={`${item.documentName}`}
                                        // secondary={`Loan Code: ${item.loanCode}`} 
                                        /> */}
                                        <ListItemText
                                            primary={item.realtorsVisibility ? `${item.documentName}` : null}
                                        //secondary={`Loan Code: ${item.loanCode}`}
                                        />


                                    </ListItem>
                                </Box>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Paper>
            <Paper sx={{ borderRadius: '8px', padding: 2, mt: 2 }}>
                <Box padding={1} bgcolor={"white"} sx={{ width: '100%', typography: 'body1' }}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gap={1}
                    >
                        <Typography variant="h3">{`Pending Tasks`}</Typography>


                    </Stack>
                    <Box my={1}>
                        <Divider />
                    </Box>

                    <Box mt={3} p={1}>
                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <Typography sx={{ color: '#2F80ED', fontSize: '16px', fontWeight: '700' }}>{realtorTaskCount?.primaryBorrower}</Typography>
                                <Typography>Primary Borrower</Typography>
                            </Grid>


                            <Grid item xs={6}>
                                <Typography sx={{ color: '#2F80ED', fontSize: '16px', fontWeight: '700' }}>{realtorTaskCount?.thirdParty}</Typography>
                                <Typography>Third Party</Typography>
                            </Grid>


                            <Grid item xs={6}>
                                <Typography sx={{ color: '#2F80ED', fontSize: '16px', fontWeight: '700' }}>{realtorTaskCount?.coApplicant}</Typography>
                                <Typography>Co-applicant</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Paper>
        </>
    );
};

export default RealtorDocumentView;
