import { Button, Grid, Box, Typography, Stack, useMediaQuery, Alert } from "@mui/material";
import React, { useContext, useState } from "react";
import LoanSummaryBorrowerInformation from "./loan-summary-borrower-profile";
import LoanSummaryMloMlpProfile from "./loan-summary-mlo-mlp-profile";
import { API } from "../../../../services/api-config";
import { downloadReportFromServer, getImageUrl } from "../../../../helpers/file-upload-download";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import { kstheme } from "../../../../theme/theme";
import { DialogCommonHooks } from '../../../../helpers/modal/modal'

export default function LoanSummaryDetailsLayout({
  loanState,
  loanStateDate,
  lockStatus,
  lockExpirationDate,
  borrowerData,
  mloData,
  mlpData,
  userType,
  mloMlpList,
  loanCode,
  reload,
}) {
  const { visiblity } = useContext(LoanSummaryParentData);
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

  // MISMO File download API
  const handleMismoExport = () => {
    downloadReportFromServer(API.mismoExport, {
      loanCode: loanCode,
    });
    // window.open(process.env.REACT_APP_BASE_URL + API.mismoExport + "?loanCode=" + loanCode, "_blank")
  };

  const [buttonEnabled, setButtonEnabled] = useState(true);
  const handleFullListDocumentExport = () => {
    setButtonEnabled(false);
    // console.log('button disabled');
    downloadReportFromServer(API.fullListDocumentExport, {
      loanCode: loanCode,
    }).then((response) => {
      //  console.log('button enaabled');
      setButtonEnabled(true);
    });

    // window.open(process.env.REACT_APP_BASE_URL + API.mismoExport + "?loanCode=" + loanCode, "_blank")
  };
  const [modalOpenBox, setModalOpenBox] = useState(false);
  const [modalOpenBoxMismo, setModalOpenBoxMismo] = useState(false);

  const handleClose = () => {
    setModalOpenBox(false);
    setModalOpenBoxMismo(false)
  };


  const handleModalOpenBox = () => {
    setModalOpenBox(true);
  };


  const handleDownloadConfirmation = () => {
    setModalOpenBox(false);
    handleFullListDocumentExport()
  };


  const handleModalOpenBoxMismo = () => {
    setModalOpenBoxMismo(true);
  };


  const handleDownloadConfirmationMismo = () => {
    setModalOpenBoxMismo(false);
    handleMismoExport()
  };


  return (
    <>
      <Grid
        container
        alignItems={"center"}
        bgcolor={"white"}
        mb={3}
        p={2}
        borderRadius={2}
      >
        {/* Borrower information */}
        <Grid item xs={12} md={8}>
          <LoanSummaryBorrowerInformation
            loanState={loanState}
            loanStateDate={loanStateDate}
            lockStatus={lockStatus}
            lockExpirationDate={lockExpirationDate}
            borrowerData={borrowerData}
          />
        </Grid>

        {/* MLO/MLP information */}
        {visiblity ? (
          <Grid item xs={12} md={4}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <LoanSummaryMloMlpProfile
                type="MLO"
                data={mloData}
                avatar={getImageUrl(mloData?.avatarDocId, mloData?.avatarDocFileName, "avatar")}
                userType={userType}
                mloMlpList={mloMlpList}
                loanCode={loanCode}
                reload={reload}
              />
              <LoanSummaryMloMlpProfile
                type="MLP"
                data={mlpData}
                avatar={getImageUrl(mlpData?.avatarDocId, mlpData?.avatarDocFileName, "avatar")}
                userType={userType}
                mloMlpList={mloMlpList}
                loanCode={loanCode}
                reload={reload}
              />
            </Stack>
          </Grid>
        ) : null}

        {/* Loan files and document infromation */}
        {visiblity ? (
          <Grid item xs={12} ml={!isMobile ? 15 : null}>
            <Stack
              my={2}
              gap={2}
              overflow={"hidden"}
              alignItems={"center"}
              direction={{ xs: "column", md: "row" }}
            >
              <Button
                variant="outlined"
                sx={{ minWidth: "auto" }}
                // onClick={handleMismoExport}
                onClick={handleModalOpenBoxMismo}
              >{`Export MISMO File`}</Button>
              <Button
                variant="outlined"
                sx={{ minWidth: "auto" }}
                disabled={!buttonEnabled}
                //onClick={handleFullListDocumentExport}
                onClick={handleModalOpenBox}
              >{`Export Full List Documents`}</Button>
              {/* <Button
                variant="outlined"
                sx={{ minWidth: "auto" }}
              >{`Export Underwriting Documents`}</Button> */}
            </Stack>
          </Grid>
        ) : null}
      </Grid>
      <DialogCommonHooks
        show={modalOpenBox}
        handleClose={handleClose}
        title={`Full List of Documents Download`}
        body={
          <>
            <Alert severity="warning" sx={{ width: '100%', my: 2 }}>
              <Typography sx={{ fontSize: '1rem' }}>
                {`Kindly verify and confirm whether all documents in the Guided Document section have been appropriately submitted and validated?`}
              </Typography>
              <Typography mt={1} sx={{ fontSize: '1rem' }}>
                {` Ensure that no documents have been skipped, are invalid, or are pending validation before download Full List of Documents.`}
              </Typography>
              <Typography mt={1} sx={{ fontSize: '1rem' }}>
                {`Please confirm to proceed download ?`}
              </Typography>
              <Stack direction={'row-reverse'} gap={2}>
                <Button variant='text' onClick={handleDownloadConfirmation}>Yes</Button>
                <Button variant='text' onClick={handleClose}>No</Button>
              </Stack>
            </Alert>

          </>
        }
      />
      <DialogCommonHooks
        show={modalOpenBoxMismo}
        handleClose={handleClose}
        title={`MISMO Download`}
        body={
          <>
            <Alert severity="warning" sx={{ width: '100%', my: 2 }}>
              <Typography sx={{ fontSize: '1rem' }}>
                {`Kindly verify and confirm whether all documents in the Guided Document section have been appropriately submitted and validated?`}
              </Typography>
              <Typography mt={1} sx={{ fontSize: '1rem' }}>
                {` Ensure that no documents have been skipped, are invalid, or are pending validation before download MISMO.`}
              </Typography>
              <Typography mt={1} sx={{ fontSize: '1rem' }}>
                {`Please confirm to proceed download ?`}
              </Typography>
              <Stack direction={'row-reverse'} gap={2}>
                <Button variant='text' onClick={handleDownloadConfirmationMismo}>Yes</Button>
                <Button variant='text' onClick={handleClose}>No</Button>
              </Stack>
            </Alert>

          </>
        }
      />
    </>


  );
}
