
// import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Box } from '@mui/material';
// import React, { useEffect, useState } from 'react';

// const MultiSelectDropDown = ({ field, options, selectAll }) => {
//     const selectedValues = field.value || []; // Ensure field.value is an array

//     const [internalSelectAll, setInternalSelectAll] = useState(selectAll); // Internal state to track selectAll

//     // Effect to handle selectAll functionality
//     useEffect(() => {
//         // Check if selectAll state is true and if selectedValues are not the same as options
//         if (selectAll && selectedValues.length !== options.length) {
//             field.onChange(options); // Select all options
//             setInternalSelectAll(true); // Update internal state
//         }
//         // Handle deselectAll when selectAll is false and everything was previously selected
//         else if (!selectAll && internalSelectAll && selectedValues.length === options.length) {
//             field.onChange([]); // Deselect all options
//             setInternalSelectAll(false); // Update internal state
//         }
//     }, [selectAll, selectedValues, options, field, internalSelectAll]); // internalSelectAll ensures the effect runs only when necessary

//     return (
//         <Box mb={1} mt={1}>
//             <FormControl fullWidth>
//                 {/* <InputLabel id="multi-select-label">Select option</InputLabel> */}
//                 <Select
//                     {...field}
//                     multiple
//                     renderValue={(selected) => {
//                         if (selected.length === 0) {
//                             return 'Select option'; // Display placeholder when nothing is selected
//                         }
//                         return selected.join(', '); // Display selected items joined by a comma
//                     }}
//                     onChange={(event) => {
//                         const value = event.target.value;
//                         field.onChange(value); // Update selected values
//                     }}
//                     value={selectedValues} // Ensure default value is an empty array
//                 >
//                     {options.map((option) => (
//                         <MenuItem key={option} value={option}>
//                             <Checkbox checked={selectedValues.indexOf(option) > -1} />
//                             <ListItemText primary={option} />
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>
//         </Box>
//     );
// };

// export default MultiSelectDropDown;
import { FormControl, Select, MenuItem, Checkbox, ListItemText, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

const MultiSelectDropDown = ({ field, options, selectAll, changeCallback }) => {
    const selectedValues = field.value || []; // Ensure field.value is an array

    const [internalSelectAll, setInternalSelectAll] = useState(false); // Track internal selectAll state

    // Effect to handle selectAll functionality when the prop changes
    useEffect(() => {
        if (selectAll && !internalSelectAll) {
            // Select all options only if selectAll is true and not already selected
            field.onChange(options);
            setInternalSelectAll(true);
            changeCallback && changeCallback(options); // Log all selected options
        } else if (!selectAll && internalSelectAll) {
            // Deselect all options if selectAll is false and was previously selected
            field.onChange([]);
            setInternalSelectAll(false);
            changeCallback && changeCallback([]); // Log the empty selection
        }
    }, [selectAll, options, field, internalSelectAll, changeCallback]);

    // Effect to reset selection if options change
    useEffect(() => {
        if (selectedValues.some((val) => !options.includes(val))) {
            field.onChange([]);
            changeCallback && changeCallback([]);
        }
    }, [options, field, changeCallback, selectedValues]);

    return (
        <Box mb={1} mt={1}>
            <FormControl fullWidth>
                <Select
                    {...field}
                    multiple
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return 'Select option'; // Display placeholder when nothing is selected
                        }
                        return selected.join(', '); // Display selected items joined by a comma
                    }}
                    onChange={(event) => {
                        const value = event.target.value;
                        field.onChange(value); // Update selected values
                        changeCallback && changeCallback(value); // Log the selected values
                    }}
                    value={selectedValues} // Ensure the default value is an empty array
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={selectedValues.includes(option)} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default MultiSelectDropDown;
