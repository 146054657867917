import React, { useEffect, useImperativeHandle, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import axios from "axios";
import { AccountCircle, Edit, Send } from "@mui/icons-material";
import { Delete } from "feather-icons-react/build/IconComponents";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import TablePagination from '@mui/material/TablePagination';

const CustomReactMaterialTable = ({
  renderTopToolbarCustomActions,
  columns,
  rowData,
  enableRowActions = false,
  enableColumnActions,
  renderRowActions,
  renderRowActionMenuItems,
  endPoint,
  params,
  enableColumnFilters = true,
  enableColumnFilterModes = false,
  enablePagination = true,
  enableSorting = true,
  enableGlobalFilter = true,
  enableFullScreenToggle = true,
  enableDensityToggle = true,

  enableToolbarInternalActions = true,
  refetch = false,
  showGlobalFilter = false,
  // for details panel in material table
  renderDetailPanel,
  positionExpandColumn = "first",
  expanded = false,
  columnVisibility,
  muiTableBodyProps,


  enableBottomToolbar,

  enableHiding = true,
  enableTopToolbar = true,
  staticLoad = false,
  muiSearchTextFieldProps = {
    placeholder: "Search",
    //sx: { minWidth: "18rem" },
    variant: "outlined",
  },
  staticData = [
    {
      firstName: "First Name",

      lastName: "Last Name",

      address: "Address",

      state: "State",

      phoneNumber: "Phone Number",
    },
  ],
  initialSorting = [],
  childFunc
}) => {



  useImperativeHandle(childFunc, () => ({

    rowCountF() {
      return rowCount

    }

  }));

  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState([]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState(initialSorting);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [searchParams, setSearchParams] = useState(() => {
    if (params) {
      return params;
    } else {
      return {};
    }
  });
  useEffect(() => {
    if (params) {
      setSearchParams(params)
    }
  }, [params])

  //if you want to avoid useEffect, look at the React Query CustomReactMaterialTable instead

  const fetchData = async () => {
    setData([])
    setRowCount(0)
    const url = new URL(endPoint, process.env.REACT_APP_BASE_URL);

    if (enablePagination) {
      url.searchParams.set(
        "start",
        `${pagination.pageIndex * pagination.pageSize}`
      );
      url.searchParams.set("size", `${pagination.pageSize}`);
    }

    enableColumnFilters
      && url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));

    enableGlobalFilter
      && url.searchParams.set("globalFilter", globalFilter ?? "");

    enableSorting
      && url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

    enableColumnFilterModes
      && url.searchParams.set(
        "filtersParm",
        JSON.stringify(columnFilterFns ?? [])
      );
    Object.keys(searchParams).length !== 0
      && Object.keys(searchParams).forEach((i) => {
        url.searchParams.set(i, searchParams[i]);
      })

    try {
      const response = await axios.get(url.href, {
        headers: { "XTR-API-Action": "read" },
      });
      const json = response;


      setData(json.data?.data);
      setRowCount(json.data?.meta?.totalRowCount);
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  useEffect(async () => {
    if (!staticLoad) {
      await fetchData()
    }
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    searchParams,
    refetch,
  ]);
  return (
    // read for customization https://www.material-react-table.dev/?path=/story/prop-playground--default
    <Box className="textEllipsis">
      <MaterialReactTable

        renderDetailPanel={renderDetailPanel}
        positionExpandColumn={positionExpandColumn}

        enableRowActions={enableRowActions}
        enableColumnActions={enableColumnActions}
        enableToolbarInternalActions={enableToolbarInternalActions}
        //
        manualFiltering
        manualPagination
        manualSorting
        //
        enableHiding={enableHiding}
        enableColumnFilterModes={enableColumnFilterModes}
        enableColumnFilters={enableColumnFilters}
        enableGlobalFilter={enableGlobalFilter}
        enablePagination={enablePagination}
        enableSorting={enableSorting}
        enableFullScreenToggle={enableFullScreenToggle}
        enableDensityToggle={enableDensityToggle}
        enableTopToolbar={enableTopToolbar}
        enableBottomToolbar={enableBottomToolbar}
        //
        columns={columns}
        data={rowData ? rowData : (staticLoad ? staticData : data)}
        initialState={{
          showColumnFilters: false,
          showGlobalFilter: showGlobalFilter,
          expanded: expanded,
          columnVisibility: columnVisibility
        }}
        //
        style={{ width: '0px', minWidth: '0px' }}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          sx: { display: isTopToolbar ? "block" : "none" },
        })}
        muiToolbarAlertBannerProps={
          isError && !rowData
            ? {
              color: "error",
              children: "Error loading data",
            }
            : undefined
        }
        muiSearchTextFieldProps={muiSearchTextFieldProps}
        onColumnFiltersChange={setColumnFilters}
        onColumnFilterFnsChange={setColumnFilterFns}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilterFns,
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
        positionToolbarAlertBanner="bottom"
        positionActionsColumn={"last"}
        renderRowActions={renderRowActions}
        renderRowActionMenuItems={renderRowActionMenuItems}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        enableMultiSort={true}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 15, 20, 30, 50, 100, 250, 500, 1000],
          showFirstButton: true,
          showLastButton: true,
        }}


      />
    </Box>
  );
};

CustomReactMaterialTable.propTypes = {
  renderTopToolbarCustomActions: PropTypes.func,
  columns: PropTypes.array.isRequired,
  rowData: PropTypes.array,
  renderRowActions: PropTypes.func,
  renderRowActionMenuItems: PropTypes.func,
  endPoint: PropTypes.string.isRequired,
  enableColumnFilters: PropTypes.bool,
  enableColumnFilterModes: PropTypes.bool,
  enablePagination: PropTypes.bool,
  enableSorting: PropTypes.bool,

  enableGlobalFilter: PropTypes.bool,

  enableFullScreenToggle: PropTypes.bool,
  enableDensityToggle: PropTypes.bool,
  params: PropTypes.any,
  enableHiding: PropTypes.bool,
  enableTopToolbar: PropTypes.bool,

  staticLoad: PropTypes.bool,
  enableColumnActions: PropTypes.bool,
  enableRowActions: PropTypes.bool,
  staticData: PropTypes.array,

  enableToolbarInternalActions: PropTypes.bool,
  muiSearchTextFieldProps: PropTypes.object,
  refetch: PropTypes.bool,
  showGlobalFilter: PropTypes.bool,
  // detailed panel
  renderDetailPanel: PropTypes.func,
  positionExpandColumn: PropTypes.string,
  expanded: PropTypes.bool,
  columnVisibility: PropTypes.any,
  muiTableBodyProps: PropTypes.any,
  enableBottomToolbar: PropTypes.any,

};

export default CustomReactMaterialTable;
