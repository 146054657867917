


import { AttachMoney, } from '@mui/icons-material'
import { Box, Button, FormHelperText, FormLabel, InputLabel, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    ACCOUNT_TYPE,
    ASSET_OWNED_BY,
    ASSET_OWNED_BY_WITHOUT_PARTNER,
    BLANK_DOCUMENT_STATUS,
    STATUS_OF_REALTOR,
    TASK_VENDOR
} from '../../../constants/constatnt-variable'
import CustomInput from '../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../helpers/custom-input-side-checkbox'
import CustomDropDown from '../../../helpers/dropdown/custom-dropdown'
import { Controller, useForm, useWatch } from 'react-hook-form'
import CustomRadioChip from '../../../helpers/radio-chip'
import RadioButtonGroupDoc from '../../../helpers/radio-button-group-doc'
import { TaskTypeValidation } from '../../../constants/validation-rules'
import { KSCloudUploadIcon } from '../../../icons/custome-icon'
import { getApiCallDynamic, uploadDocCustom } from '../../../services/api-service'
import { API } from '../../../services/api-config'
import { toastNotify } from '../../../helpers/alert-msg'


export default function DocumentLibraryForm({ handleClose, onSubmit, editItem, hasPartner, setUploadData, docList, refreshStatus, setRefreshStatus }) {





    const [fileNameToView, setFileNameToView] = useState(editItem?.fileName);



    const { control, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {

            id: editItem?.id,
            documentNote: editItem?.documentNote,
            documentType: editItem?.documentType,
            taskType: editItem?.taskMaster?.id,
            blankDocument: editItem?.blankDocument === true ? 'Yes' : 'No',
            documentDescriptor: editItem?.documentDescriptor,
            fileName: editItem?.blankDocument === true ? editItem?.fileName : null,
            storageKey: editItem?.blankDocument === true ? editItem?.storageKey : null,
        }
    })



    const {
        blankDocument,
        brokerDocId,
        documentType,
        taskType,
        documentDescriptor,
        fileName,
        storageKey

    } = useWatch({ control });


    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState(null);






    const handleFileChange = async (event) => {

        if (blankDocument === 'Yes') {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);


            const uploadPath = API.uploadDoc
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('taskTypeId', taskType);
            formData.append('documentType', documentType);
            formData.append('documentDescriptor', documentDescriptor);
            formData.append('serviceType', 'DocLib');


            try {

                const response = await uploadDocCustom({ formData, path: uploadPath });

                setUploadData(response.data)
                setFileNameToView(response.data.fileName)
                setUploadResponse(response.data);
                toastNotify("success", 'File uploaded successfully');
                setRefreshStatus(!refreshStatus)
                setValue
            } catch (error) {
                console.error('Error uploading file:', error);
                toastNotify("error", 'Error uploading file');

            }
        }
    };



    useEffect(() => {
        if (blankDocument === 'No') {
            setValue('fileName', null)
            setValue('storageKey', null)
            setValue('blankDocument', 'No')

        }
        else if (blankDocument === 'Yes') {
            setValue('fileName', editItem?.fileName)
            setValue('storageKey', editItem?.storageKey)
            setValue('blankDocument', 'Yes')

        }

    }, [blankDocument])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Box width={'100%'} my={2} >
                <Box>

                    <Controller
                        name='taskType'
                        control={control}
                        rules={TaskTypeValidation.taskType}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Select the Task Type'}
                                required
                                //state={taskType}
                                option={docList}
                                error={errors?.taskType?.message}
                            />
                        )}
                    />

                    <Controller
                        name="documentType"
                        control={control}
                        rules={TaskTypeValidation.documentType}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'Enter document type type'}
                                type={'text'} label={'Enter Document Type'} required error={errors?.documentType?.message} />
                        )}
                    />

                    <Controller
                        name="documentDescriptor"
                        control={control}
                        rules={TaskTypeValidation.documentDescriptor}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'None'}
                                type={'text'} label={'Enter Document Descriptor'} required error={errors?.documentDescriptor?.message} />
                        )}
                    />

                    <Controller
                        name="documentNote"
                        control={control}
                        rules={TaskTypeValidation.documentNote}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'Enter the instructions for filling the document'}
                                type={'text'} label={'Note for Filling the Document'} required error={errors?.documentNote?.message}
                                multiline
                                rows={5}
                            />
                        )}
                    />



                    <Controller
                        name="blankDocument"
                        control={control}
                        rules={TaskTypeValidation.isBlankDoc}
                        render={({ field }) => (
                            <RadioButtonGroupDoc
                                {...field}
                                field={field}
                                required
                                direction="row"
                                labelComponent={<FormLabel>{`Is Blank document available?`}</FormLabel>}
                                data={BLANK_DOCUMENT_STATUS}
                                error={errors?.isBlankDoc?.message}
                            />
                        )}
                    />






                </Box>

                <Box marginY={4} textAlign={'right'}>

                    {
                        blankDocument === 'Yes' &&

                        <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                            my={2}
                            justifyContent={"space-between"}
                        >
                            {/* <InputLabel>{`Document to Sign`}
                                                <Typography component={'span'} color={'error'}>{`*`}</Typography>
                                            </InputLabel> */}


                            <Button
                                component="label"
                                size="small"
                                // {...field}
                                // value={brokerFileID}
                                startIcon={<KSCloudUploadIcon color={"primary"} />}
                                sx={{
                                    mb: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1
                                }}
                            >
                                <input
                                    onChange={handleFileChange}
                                    hidden
                                    accept="image/*"
                                    type="file"
                                />
                                <Typography paragraph>
                                    {fileNameToView ? `Reupload` : `Upload`}
                                </Typography>

                                <Typography paragraph> {fileNameToView}</Typography>
                            </Button>

                        </Stack>



                    }

                    {/* <Button type='submit' variant='contained' >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button> */}

                    <Button
                        type="button"
                        onClick={handleClose}

                        sx={{

                            width: '50%',
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        variant="contained"
                        disabled={blankDocument === 'Yes' && !fileNameToView}
                        sx={{

                            width: '50%',
                        }}
                    >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </Box>
        </form>

    )
}