import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { formState, profileState } from "../../../recoil/profile-atom";
import { Link } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";
import { kstheme } from "../../../theme/theme";
import { Path } from "../../../routes/route-config";
import AddressHelper from "../address-helper";

export default function LoanTopBar({ mobileNvbarOpen }) {
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
  let [totalProgress, setTotalProgress] = useState(0);
  let formData = useRecoilValue(formState);
  let profileInfo = useRecoilValue(profileState);
  const { loanDetails, realEstate, liabilities, borrowerInfo: { page: { contact, personal, employment, additionalIncome } } } = formData;
  const activeTab =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const getExcludedProp = () => {
    let address = []
    liabilities?.page?.expense?.map((emp) => {
      if (emp?.isTaxesInsuranceIncluded) {
        address.push(emp.propertyAddress)
      }
    })
    liabilities?.page?.mortgage?.map((emp) => {
      if (emp?.isTaxesInsuranceIncluded) {
        address.push(emp.propertyAddress)
      }
    })
    liabilities?.page?.nonMortgage?.map((emp) => {
      if (emp?.isTaxesInsuranceIncluded) {
        address.push(emp.propertyAddress)
      }
    })
    return address;
  }

  const frontendDTI = () => {
    // todo: isTaxesInsuranceIncluded: true -> exclude Monthly Property Insurance, Monthly Property Taxes from realestate
    let housingExp = null;
    const omitAddress = getExcludedProp();
    let monthlyDebtExp = null;
    let grossMonthlyInc = null;
    let estates = realEstate?.page;
    let realEstateExp = null;
    let employmentExp = employment?.subPage
    const additionalInc = additionalIncome?.subPage?.additionalIncome
    const loanDetail = loanDetails?.page;
    const liability = liabilities?.page;
    housingExp += Number(loanDetail?.firstMortgagePayment) + Number(loanDetail?.propertyInsurance) + Number(loanDetail?.solarleaseapyment) + Number(loanDetail?.countryPropertyTax) + Number(loanDetail?.mortgageInsurance) + Number(loanDetail?.HOADues)
    estates.map((estate) => {
      const isOmit = omitAddress.includes(estate.addressLine1)
      if (loanDetail?.buyingOrRefinancing != "Refinancing" || (!(estate.addressLine1 === loanDetail.addressLine1) && loanDetail?.buyingOrRefinancing === "Refinancing")) {
        realEstateExp += (estate.monthlyIncome - ((isOmit ? 0 : estate.propertyInsurance) + (isOmit ? 0 : estate.propertyTaxes) + estate.solarLeasePayment + estate.hoaPayment))
      }
    })
    grossMonthlyInc += realEstateExp + Number(loanDetail?.investmentMonthIncome)
    employmentExp?.employed?.map((emp) => {
      if (!emp.endDate) {
        grossMonthlyInc += emp.monthlyIncome + emp.avgMonthlyComission + emp.avgMonthlyIncome + emp.avgMonthlyOvertime + emp.militaryEntitlement
      }
    })
    employmentExp?.retired?.map((emp) => {
      grossMonthlyInc += Number(emp.retirementIncome)
    })

    employmentExp?.secondary?.map((emp) => {
      grossMonthlyInc += Number(emp.monthlyIncome)
    })

    employmentExp?.selfEmployed?.map((emp) => {
      if (!emp.endDate) {
        grossMonthlyInc += Number(emp.monthlyIncome)
      }
    })

    grossMonthlyInc += (Number(employmentExp?.sideIncome?.avgMonthlyComission) || 0) + (Number(employmentExp?.sideIncome?.avgMonthlyIncome) || 0) + (Number(employmentExp?.sideIncome?.avgMonthlyOvertime) || 0) + (Number(employmentExp?.sideIncome?.militaryEntitlement) || 0)

    additionalInc?.map((emp) => {
      grossMonthlyInc += Number(emp?.monthIncome)
    })

    monthlyDebtExp += housingExp;
    liability.expense?.map((item) => {
      if (!item.omitStatus) {
        monthlyDebtExp += item.monthlyPay
      }
    })
    liability.mortgage?.map((item) => {
      if (!item.omitStatus) {
        monthlyDebtExp += item.monthlyPay
      }
    })
    liability.nonMortgage?.map((item) => {
      if (!item.omitStatus) {
        monthlyDebtExp += item.monthlyPay
      }
    })
    const backendDTI = (monthlyDebtExp / grossMonthlyInc * 100).toFixed(3);
    const frontendDTI = ((housingExp / grossMonthlyInc) * 100).toFixed(3);
    return { backendDTI: backendDTI, frontendDTI: frontendDTI }
  }
  // find the value of progress bar
  const countIsCompleteTrue = (obj) => {
    let count = 0;
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (typeof value === "object" && value !== null) {
          if (Object.prototype.hasOwnProperty.call(value, "isComplete") && value.isComplete === true) {
            count++;
          } else {
            count += countIsCompleteTrue(value); // Recursive call for nested objects
          }
        }
      }
    }
    return count;
  };
  const countIsCompleteTrueProgress = (obj) => {
    let count = 0;
    if (obj.getStarted) {

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {

            if (Object.prototype.hasOwnProperty.call(value, "isComplete") && value.isComplete === true) {
              count++;
            }
          }
        }
      }
      return count;
    }
  }


  const progressCalculator = () => {
    let totalValue = 0
    // let length = Object.keys(formData).length;
    // let subLength = Object.keys(formData?.borrowerInfo?.page).length;
    // let count = 0;
    // let countSub = 0;
    // let perSlice = 100 / length;
    // let subSlice = perSlice / subLength;

    // Object.keys(formData).forEach((i) => {
    //   if (formData[i]?.isComplete == true) {
    //     count++;
    //   }
    // });
    // Object.keys(formData?.borrowerInfo?.page).forEach((i) => {
    //   if (formData?.borrowerInfo?.page[i]?.isComplete == true) {
    //     countSub++;
    //   }
    // });
    // if (formData?.hasPartner && formData?.hasPartner == true) {
    //   Object.keys(
    //     formData?.partnerInfo?.page ? formData?.partnerInfo?.page : {}
    //   ).forEach((i) => {
    //     if (formData?.partnerInfo?.page[i]?.isComplete == true) {
    //       countSub++;
    //     }
    //   });
    // }
    // let totalSliceValue = perSlice * count + subSlice * countSub;
    if (formData?.hasPartner) {
      totalValue = (100 / 12) * countIsCompleteTrueProgress(formData);
    } else {
      totalValue = (100 / 10) * countIsCompleteTrueProgress(formData);
    }

    setTotalProgress(totalValue > 100 ? 100 : totalValue);
  };
  useEffect(() => {
    progressCalculator();
  }, [formData, window.location.pathname]);
  const DTIValues = frontendDTI();
  //const summaryNotSeenTab = ['liabilities', 'assets-credits', 'gift-grants', 'credit-report', 'guided-document', 'additional-document']
  const summaryNotSeenTab = [];


  const loanCodePath = profileInfo.role !== 'Borrower'
    ? `${Path.loanSummary}/${formData.loanCode}`
    : `${location.pathname.split('/').slice(0, -2).join('/')}`;



  return (
    <>
      <Box
        display={"flex"}
        px={{ xs: 1, md: 2 }}
        py={{ xs: 1, md: 3 }}
        boxShadow={1}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          {isMobile && (
            <IconButton onClick={mobileNvbarOpen} color="fullWhite" sx={{ backgroundColor: 'primary.main', marginLeft: '6px' }} size="small">
              <MoreVert fontSize="inherit" />
            </IconButton>
          )}
          {isMobile ? (
            <Typography variant="h6" fontWeight={'600'} minWidth={isMobile ? '50%' : '100%'} maxWidth={'min-content'}>Loan App.</Typography>

          ) : (
            <Typography variant="h6" fontWeight={'600'} minWidth={isMobile ? '25%' : '100%'} maxWidth={'min-content'}>Loan Application</Typography>
          )}



          {/* {profileInfo.role !== 'Borrower' && (
            <Link to={Path.loanSummary + "/" + formData.loanCode}>
              <Typography variant="p">{formData.loanCode}</Typography>
            </Link>)
          } */}

          {profileInfo.role !== 'Borrower' && (
            <Link to={Path.loanSummary + "/" + formData.loanCode}>
              <Typography variant="p">{formData.loanCode}</Typography>
            </Link>)}
          {profileInfo.role === 'Borrower' && (
            <Link to={loanCodePath + "/" + Path.loanSummary + "/" + formData.loanCode}>
              <Typography variant="p">{formData.loanCode}</Typography>
            </Link>)}

        </Stack>
        {profileInfo.role !== 'Borrower' && !summaryNotSeenTab.includes(activeTab) &&
          <Grid style={{ maxWidth: '50%' }} item xs={12} direction={'row'} display={'flex'} justifyContent={'center'}
          >
            {!isMobile ? (
              <Typography variant="h6" fontWeight={'600'} >{`${personal.subPage?.firstName} ${personal.subPage?.lastName}`}</Typography>
            ) : null}
            <Grid ml={3}>
              <Grid
                item
                xs={6}
                borderRight={2}
                pr={{ xs: 1, md: 0 }}
                borderColor={"#C4C4C420"}
                minWidth={'100%'}
              >


                {isMobile ? null : (
                  <Typography variant="p">Address: {loanDetails?.page?.addressLine1}, {loanDetails?.page?.addressLine2}, {" "} {loanDetails?.page?.city}, {""}{loanDetails?.page?.state} {loanDetails?.page?.zip}</Typography>
                )}

              </Grid>

              <Grid>
                {!isMobile ? (
                  isNaN(DTIValues.frontendDTI) || isNaN(DTIValues.backendDTI) ? (
                    <Typography variant="p">Calculated DTI: - </Typography>
                  ) : (
                    <Typography variant="p">
                      Calculated DTI: {DTIValues.frontendDTI} / {DTIValues.backendDTI} %
                    </Typography>
                  )
                ) : null}
              </Grid>


            </Grid>
          </Grid>}



        {!isMobile ? (
          <Link to={"/"} replace>
            <Button variant={"text"} size="large">
              Resume Later
            </Button>
          </Link>) : null}


      </Box >
      {isMobile ? (<Grid>
        <Box ml={6} mt={2}>
          {isMobile ? (
            <Typography variant="h6" fontWeight={'600'} >{`${personal.subPage?.firstName} ${personal.subPage?.lastName}`}</Typography>

          ) :
            null
          }</Box>
        {isMobile ? (<AddressHelper loanDetails={loanDetails} DTIValues={DTIValues} />) : null}
        {isMobile ? <Box ml={5}><Link to={"/"} replace>
          <Button variant={"text"} size="large">
            Resume Later
          </Button>
        </Link> </Box> : null}
      </Grid>) : null}
      <Box>
        <LinearProgress style={{ height: '9px' }} color='success' variant="determinate" value={totalProgress} />
      </Box>
    </>



  );
}
