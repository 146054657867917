import React, { createContext, useState, useEffect } from 'react';
const BillContext = createContext();
import axios from 'axios';
import { API } from '../../../services/api-config'

const BillContextProvider = ({ children }) => {

    const [charges, setCharges] = useState([]);
    const [billing, setBilling] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userCode, setUserCode] = useState('');
    const [isNewUser, setIsNewUser] = useState(null);
    const [tndAcceptd, setTndAccepted] = useState(null);
    const [ssn, setSsn] = useState(null);
    const [billUserCode, setBillUserCode] = useState(null);
    const [paymentSetUpDone, setPaymentSetUpDone] = useState(null)
    const [paymentSetUpDate, setPaymentSetUpDate] = useState(null)
    const [ownerName, setOwnerName] = useState(null);
    const [address, setAddress] = useState(null);


    useEffect(() => {

        const fetchUserCode = () => {
            const localStorageData = localStorage.getItem('cliqloan-user-app-session');
            if (localStorageData) {
                const parsedData = JSON.parse(localStorageData);
                const code = parsedData?.data?.userCode;
                setUserCode(code);
            }
        };

        fetchUserCode();
        let isMounted = true;

    }, []);
    useEffect(() => {

        const fetchUserCode = () => {
            const localStorageData = localStorage.getItem('cliqloan-user-app-session');
            if (localStorageData) {
                const parsedData = JSON.parse(localStorageData);
                const code = parsedData?.data?.userCode;
                setUserCode(code);
            }
        };

        fetchUserCode();
        let isMounted = true;

    }, [isNewUser]);


    const fetchData = () => {

        // const BASE_URL = "https://stage.kramasoft.com/ks-bill/api/v1/user"
        const BASE_URL = API.billUser

        const BILL_TERM_NO_ACCEPT = "billing-term?userCode="
        const KS_USER_CODE = userCode;
        const BillingAPI_URL = `${BASE_URL}/${BILL_TERM_NO_ACCEPT}${KS_USER_CODE}`;


        //Final
        axios
            .get(BillingAPI_URL)
            .then((response) => {

                setCharges(response.data.ksRate);
                setDiscounts(response.data.ksDiscount);
                setIsNewUser(response.data.billingTerm);
                setTndAccepted(response.data.billingTermAcceptedOn)
                setBillUserCode(response.data.userCode)
                setOwnerName(response.data.userName)
                setAddress(response.data.userAddress)
                setPaymentSetUpDone(response.data.paymentSetUpDone)
                setPaymentSetUpDate(response.data.paymentSetUpDate)
                setSsn(response.data.ssn)
                setLoading(false);


            })
            .catch((error) => {
                console.error("Error fetching charges data:", error);
                setLoading(false);
            });
    }



    useEffect(() => {

    }, [isNewUser]);


    return (
        <BillContext.Provider value={{
            charges, discounts, loading, tndAcceptd, billUserCode, userCode, paymentSetUpDone, paymentSetUpDate, ssn,
            billing, isNewUser, ownerName, address, setIsNewUser, fetchData
        }}>
            {children}
        </BillContext.Provider>
    );
};

export { BillContext, BillContextProvider };