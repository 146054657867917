import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography, } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../recoil/profile-atom";
import { KSArrowLeftIcon } from "../../icons/custome-icon";
import { useMediaQuery, useTheme } from '@mui/material';

export const DialogCommonHooks = (props) => {
  let [spinner, setSpinner] = useRecoilState(globalSpinner);
  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle
        sx={{ fontWeight: "bold", display: props?.title ? "" : "none", paddingRight: '32px' }}
      >
        {props?.title}

        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>


      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "black",
        }}
      >
        <CloseIcon sx={{ display: props?.onlyCloseIcon ? null : "none" }} />
      </IconButton>


      <DialogContent style={{ borderTop: '7px solid #2F80ED' }}>
        <LoadingOverlay
          active={spinner}
          spinner={true}
          text="Please wait a moment"
        >{props.body}
        </LoadingOverlay>
      </DialogContent>
      {props.footer ? (
        <DialogActions sx={{ py: 3 }}>{props.footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};



export const PreviewPDFModal = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      aria-labelledby="File-Preview"
      PaperComponent={Box}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box pb={5} position={'relative'}>
          <Tooltip title={'Close'}>
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                left: 16,
                top: 0,
                p: 0.5,
                color: "white"
              }}
            >
              <KSArrowLeftIcon onClick={props.handleClose} />
              <Typography pl={1}>
                {"Generated Pre-approval/Quote"}
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>
        {props.body}
      </DialogContent>
      {props.footer ? (
        <DialogActions sx={{ py: 3 }}>{props.footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};


// export const DocumentDialogCommonHooks = (props, isPreview) => {
//   const [spinner, setSpinner] = useRecoilState(globalSpinner);

//   return (
//     <Dialog
//       fullWidth
//       maxWidth={props.size} // Use the passed prop for maxWidth
//       open={props.show}
//       aria-labelledby="customized-dialog-title"
//       sx={{ width: props.size }} // Set the width based on props
//     >
//       <div style={{ position: 'relative' }}>
//         <div style={{ height: '10px', backgroundColor: '#2F80ED' }}></div>

//         <Grid container justifyContent="center" alignItems="center">
//           <Grid item md={10}>
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//                 paddingX: 5,
//                 paddingTop: 5,
//                 color: 'black',
//                 boxSizing: 'border-box'
//               }}
//             >
//               <Typography variant="h6" sx={{ flexGrow: 1 }}>
//                 {props.title}
//               </Typography>
//               <IconButton
//                 aria-label="close"
//                 onClick={props.handleClose}
//                 sx={{ color: 'white' }}
//               >
//                 <CloseIcon sx={{ color: 'black' }} />
//               </IconButton>
//             </Box>

//             <Box sx={{ paddingX: 2 }}>
//               <DialogContent>
//                 <LoadingOverlay
//                   active={spinner}
//                   spinner={true}
//                   text="Please wait a moment"
//                 >
//                   {props.body}
//                 </LoadingOverlay>
//               </DialogContent>

//               {props.footer && (
//                 <DialogActions sx={{ py: 2 }}>{props.footer}</DialogActions>
//               )}
//             </Box>
//           </Grid>
//         </Grid>
//       </div>
//     </Dialog>
//   );
// };

export const DocumentDialogCommonHooks = (props, isPreview) => {
  const [spinner, setSpinner] = useRecoilState(globalSpinner);

  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      aria-labelledby="customized-dialog-title"
      sx={{ width: props.size }}
    >
      <div style={{ position: 'relative' }}>
        {/* Fixed top bar within the dialog */}
        <div
          style={{
            height: '10px',
            backgroundColor: '#2F80ED',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
          }}
        ></div>

        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={10}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingX: 5,
                paddingTop: 5,
                color: 'black',
                boxSizing: 'border-box'
              }}
            >
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {props.title}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={{ color: 'white' }}
              >
                <CloseIcon sx={{ color: 'black' }} />
              </IconButton>
            </Box>

            <Box sx={{ paddingX: 2 }}>
              <DialogContent>
                <LoadingOverlay
                  active={spinner}
                  spinner={true}
                  text="Please wait a moment"
                >
                  {props.body}
                </LoadingOverlay>
              </DialogContent>

              {props.footer && (
                <DialogActions sx={{ py: 2 }}>{props.footer}</DialogActions>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};



// export const DocumentDialogCommonHooks = (props) => {
//   const [spinner, setSpinner] = useRecoilState(globalSpinner);
//   const theme = useTheme();

//   // Use MediaQuery to check if the screen size is small
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

//   return (
//     <Dialog
//       fullWidth
//       maxWidth={isSmallScreen ? "sm" : "lg"} // Adjust maxWidth for small screens
//       open={props.show}
//       aria-labelledby="customized-dialog-title"
//       sx={{
//         width: '100%',
//         maxWidth: isSmallScreen ? '100%' : '50%', // Prevent overflow on mobile
//       }}
//     >
//       <div style={{ position: 'relative' }}>
//         {/* Fixed top bar within the dialog */}
//         <div
//           style={{
//             height: '10px',
//             backgroundColor: '#2F80ED',
//             position: 'sticky',
//             top: 0,
//             zIndex: 1000,
//           }}
//         ></div>

//         <Grid container justifyContent="center" alignItems="center">
//           <Grid item xs={12} md={10}>
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//                 paddingX: isSmallScreen ? 2 : 0, // Adjust padding for small screens
//                 paddingTop: 0, // Adjust padding
//                 color: 'black',
//                 boxSizing: 'border-box',
//               }}
//             >
//               <Typography variant="h6" sx={{ flexGrow: 1 }}>
//                 {props.title}
//               </Typography>
//               <IconButton
//                 aria-label="close"
//                 onClick={props.handleClose}
//                 sx={{ color: 'white' }}
//               >
//                 <CloseIcon sx={{ color: 'black' }} />
//               </IconButton>
//             </Box>

//             <Box sx={{ paddingX: 2 }}>
//               <DialogContent>
//                 <LoadingOverlay
//                   active={spinner}
//                   spinner={true}
//                   text="Please wait a moment"
//                 >
//                   {props.body}
//                 </LoadingOverlay>
//               </DialogContent>

//               {props.footer && (
//                 <DialogActions sx={{ py: 2 }}>{props.footer}</DialogActions>
//               )}
//             </Box>
//           </Grid>
//         </Grid>
//       </div>
//     </Dialog>
//   );
// };
export const DocumentDialogCommonHooksTask = (props) => {
  const [spinner, setSpinner] = useRecoilState(globalSpinner);
  const theme = useTheme();

  // Use MediaQuery to check if the screen size is small
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullWidth
      maxWidth={isSmallScreen ? "sm" : "lg"} // Adjust maxWidth for small screens
      open={props.show}
      aria-labelledby="customized-dialog-title"
      sx={{
        width: '100%',
        maxWidth: isSmallScreen ? '95%' : '50%', // Prevent overflow on mobile
        margin: 'auto', // Ensures the dialog is centered
      }}
    >
      <div style={{ position: 'relative' }}>
        {/* Fixed top bar within the dialog */}
        <div
          style={{
            height: '10px',
            backgroundColor: '#2F80ED',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
          }}
        ></div>

        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={10}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingX: isSmallScreen ? 0 : 0, // Adjust padding for small screens
                paddingTop: 2, // Adjust padding
                color: 'black',
                boxSizing: 'border-box',
              }}
            >
              <Typography variant="h6" sx={{ flexGrow: 1, ml: 5, mt: 2 }}>
                {props.title}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={{ color: 'white' }}
              >
                <CloseIcon sx={{ color: 'black' }} />
              </IconButton>
            </Box>

            <Box sx={{ paddingX: 2 }}>
              <DialogContent>
                <LoadingOverlay
                  active={spinner}
                  spinner={true}
                  text="Please wait a moment"
                >
                  {props.body}
                </LoadingOverlay>
              </DialogContent>

              {props.footer && (
                <DialogActions sx={{ py: 2 }}>{props.footer}</DialogActions>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};