// // TaskProvider.js
// import React, { useState } from 'react';
// import { TaskContext } from './TaskContext';

// const TaskProvider = ({ children }) => {
//     const [taskState, setTaskState] = useState({
//         borrowerTask: {
//             // formId: '',
//             // borrowerName: '',
//         },
//         thirdPartyTask: {
//             // taskTabCount: 0,
//             // tasksLists: [],
//             // taskReload: false,
//             // taskTabValue: 0,
//         },
//         supportingDocs:{}
//     });

//     // Function to update borrower task
//     const updateBorrowerTask = (newBorrowerTask) => {
//         setTaskState((prevState) => ({
//             ...prevState,
//             borrowerTask: { ...prevState.borrowerTask, ...newBorrowerTask },
//         }));
//     };

//     // Function to update third-party task
//     const updateThirdPartyTask = (newThirdPartyTask) => {
//         setTaskState((prevState) => ({
//             ...prevState,
//             thirdPartyTask: { ...prevState.thirdPartyTask, ...newThirdPartyTask },
//         }));
//     };



//     return (
//         <TaskContext.Provider value={{ taskState, updateBorrowerTask, updateThirdPartyTask }}>
//             {children}
//         </TaskContext.Provider>
//     );
// };

// export default TaskProvider;
// TaskProvider.js
import React, { useState } from 'react';
import { TaskContext } from './TaskContext';

const TaskProvider = ({ children }) => {
    const [taskState, setTaskState] = useState({
        contextBorrowerTasks: {  // Remains the same
            // Add default properties if needed
            // formId: '',
            // borrowerName: '',
        },
        contextThirdPartyTasks: {  // Remains the same
            // taskTabCount: 0,
            // tasksLists: [],
            // taskReload: false,
            // taskTabValue: 0,
        },
        contextSupportingDocs: [],
    });

    // Function to update contextBorrowerTasks
    const updateContextBorrowerTasks = (newContextBorrowerTasks) => {
        setTaskState((prevState) => ({
            ...prevState,
            contextBorrowerTasks: { ...prevState.contextBorrowerTasks, ...newContextBorrowerTasks },
        }));
    };

    // Function to update contextThirdPartyTasks
    const updateContextThirdPartyTasks = (newContextThirdPartyTasks) => {
        setTaskState((prevState) => ({
            ...prevState,
            contextThirdPartyTasks: { ...prevState.contextThirdPartyTasks, ...newContextThirdPartyTasks },
        }));
    };

    // Insert a new document into contextSupportingDocs
    const insertSupportingDoc = (newDoc) => {
        setTaskState((prevState) => ({
            ...prevState,
            contextSupportingDocs: [
                ...prevState.contextSupportingDocs,
                newDoc, // Add the new document
            ],
        }));
    };

    // Delete a document from contextSupportingDocs by key-value match
    const deleteSupportingDoc = (key, value) => {
        setTaskState((prevState) => ({
            ...prevState,
            contextSupportingDocs: prevState.contextSupportingDocs.filter((doc) => doc[key] !== value), // Filter out the document
        }));
    };

    // Update an existing document in contextSupportingDocs by key-value match
    const updateSupportingDoc = (key, value, updatedDoc) => {
        setTaskState((prevState) => ({
            ...prevState,
            contextSupportingDocs: prevState.contextSupportingDocs.map((doc) => {
                if (doc[key] === value) {
                    return { ...doc, ...updatedDoc }; // Update the document with the new values
                }
                return doc; // Return the document unchanged if it doesn't match
            }),
        }));
    };

    // Clear all documents in contextSupportingDocs
    const clearSupportingDocs = () => {
        setTaskState((prevState) => ({
            ...prevState,
            contextSupportingDocs: [], // Reset the documents list to empty
        }));
    };


    return (
        <TaskContext.Provider
            value={{
                taskState,
                updateContextBorrowerTasks,
                updateContextThirdPartyTasks,

                insertSupportingDoc,
                deleteSupportingDoc,
                updateSupportingDoc,
                clearSupportingDocs,
            }}
        >
            {children}
        </TaskContext.Provider>
    );
};

export default TaskProvider;
