


import { Box, Button, FormHelperText, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks, DocumentDialogCommonHooks } from '../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../helpers/table/react-material-table'
import { API } from '../../../services/api-config'
import ConfirmationModel from '../../../helpers/modal/confirm-modal'
import { KSPencilAltIcon, KSTrashIcon } from '../../../icons/custome-icon'
import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from '../../../services/api-service'
import { CREATE, PUT, UPDATE } from '../../../constants/constatnt-variable'
import { kstheme } from '../../../theme/theme'
import TaskTypeForm from './TaskTypeForm'


export default function TaskType() {

    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const route = useNavigate()
    const location = useLocation();


    const [docList, setDocList] = useState([])
    const [refreshStatus, setRefreshStatus] = useState(false)

    const updateState = () => {
        setRefreshStatus(true)
    }

    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTask,
            })
                .then((res) => {
                    if (res?.status === 200 && Array.isArray(res?.data)) {

                        const sortedData = res.data.sort((a, b) => {
                            const taskTypeA = a.taskTypeName || '';
                            const taskTypeB = b.taskTypeName || '';
                            return taskTypeA.localeCompare(taskTypeB);
                        });

                        setDocList(sortedData);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        taskListsData();
    }, [refreshStatus]);





    let [customError, setCustomerror] = useState("")
    const [editState, setEditState] = useState(() => {
        return null
    })

    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(docList)
            let editItem = list.find((item, i) => i == editState)
            setDocEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            docList.splice(deleteState, 1)
            setDocList([...docList])
            setDeleteState(null)
        }

    }, [deleteState])



    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "taskTypeName",
                header: "Task Type",
                enableColumnActions: false,
            },
            {
                accessorKey: "taskTypeDescription",
                header: "Task Description",
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            maxWidth: '300px',
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row.original['taskTypeDescription']}
                        </Typography>
                    </Box>
                ),
            },
            {
                accessorKey: "taskTypeFor",
                header: "Task For",
                enableColumnActions: false,
                Cell: ({ row }) => (row.original.taskTypeFor).charAt(0).toUpperCase() + (row.original.taskTypeFor).slice(1),

            },


        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [docEdit, setDocEdit] = useState();
    const handleClickOpen = () => {

        setDocEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }

    const docsSubmit = async (data) => {



        if (editState != null) {
            docList[editState] = {
                ...docList[editState],
                ...data
            };
            setEditState(null);
        } else {
            docList.push(data);
        }

        setDocList([...docList]);
        handleClose();




        // if (docList.length === 0) {
        //     // setCustomError("Please enter at least one asset");
        //     return; 
        // }

        // Clear any existing errors
        // setCustomError("");


        const apiCallData = {
            "id": data.id,
            "taskTypeName": data.taskTypeName,
            "taskTypeDescription": data.taskTypeDescription,
            "taskTypeFor": data.taskTypeFor
        };





        try {
            await postApiCallDynamicWithOutReturn({
                data: apiCallData,
                path: API.createMasterTask,
                //  header: CREATE,
                header: docEdit?.length !== 0 ? UPDATE : CREATE,
                refresh: updateState
            });
        } catch (error) {
            console.error("API call failed", error);
            // setCustomError("An error occurred while submitting the form.");
        }
        handleClose()
    };






    return (
        <>

            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box >


                    <CustomReactMaterialTable
                        staticLoad={true}
                        staticData={docList}
                        columns={tableColumn}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting={false}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={() => (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mr: isMobile ? '1rem' : '3rem', mt: isMobile ? '0.5rem' : '2rem' }}>
                                <Button sx={{ width: '10%' }} variant="outlined" onClick={handleClickOpen}>
                                    Add
                                </Button>
                            </Box>
                        )}
                        enableRowActions={true}
                        renderRowActions={
                            ({ row }) => {

                                return (
                                    <Stack direction={'row'}>
                                        <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton>


                                        {/* <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {
                                                    // setDeleteState(row.index)
                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove  ${row?.original?.documentType} - Savings?`}
                                            title={'Delete Document'}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        /> */}

                                    </Stack>
                                )
                            }
                        }
                    />
                    {customError && <FormHelperText error>{customError}</FormHelperText>}
                </Box>



                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>

                </Box>
            </Stack>

            <DocumentDialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={docEdit?.length !== 0 ? `Update Task Type` : `Add Task Type`}
                body={
                    <TaskTypeForm
                        onSubmit={docsSubmit}
                        editItem={docEdit}

                        handleClose={handleClose}
                    />
                }
            />


        </>
    )
}
