import React, { useState } from "react";
import { Box, Button, Container, Grid, InputLabel, Stack, TextField, Typography, } from "@mui/material";
import heroImage from "../../assets/heroBg.png";
import KsIcon from "../../assets/ks_check.svg";
import mloBanner from "../../assets/mloBanner.svg";
import MLOBanner2 from "../../assets/mloBanner2.svg";
import MloBannerNew from "../../assets/MloBannerNew.svg";
import ProductBg from "../../assets/productBg.png";
import ScreenShot from "../../assets/KsScrren.png";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { DialogCommonHooks } from "../../helpers/modal/modal";
import CustomInput from "../../helpers/custom-input";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SucessDemoImage from "../../assets/sucess.png";
import CustomInputPhone from "../../helpers/custom-input-phone";
import { APIConfig } from "../../auth/config";
import DemoScheduleFormWithMsg from "./demo-schedule-bottom";
import { useMediaQuery } from "@mui/material";
import { kstheme } from "../../theme/theme"

const customStyle = makeStyles({
  bodyBgColor: {
    backgroundColor: "white",
  },
  bgSolution: {
    backgroundImage: "url('/images/bgSolution.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "12%",
    backgroundPosition: "right 17% center",
  },
  cardHover: {
    backgroundColor: "#f5f9fa",
    padding: "40px",
    transition: "0.5s all",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "rgba(0, 0, 0, 10%) 0px 7px 20px 0px",
      transition: "0.5s all",
    },
  },
  aboutUsText: {},
  demoButton: {
    backgroundColor: "#FFFFFF !important",
    color: "#2F80ED !important",
    fontWeight: "bold !important",
    "&:hover": {
      backgroundColor: "#f7f4f4 !important",
      color: "#2F80ED !important",
      fontWeight: "bold !important",
    },
  },
});

function LandingMainBody() {
  const classes = customStyle();
  const router = useNavigate();
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
  // Schedule demo state

  const [openScheduleModal, setScheduleModalOpen] = useState(false);

  const handleScheduleModalOpen = () => {
    // setScheduleModalOpen(true);
    setScheduleModalOpen(true);
  };

  const handleScheduleModalClose = () => {
    setScheduleModalOpen(false);
  };
  const [invitationMessage, setInvitationMessage] = useState('');
  // Demo sucess modal
  const [openDemoSuccessModal, setDemoSuccessModalOpen] = useState(false);

  const handleDemoSuccessModalOpen = () => {
    setDemoSuccessModalOpen(true);
  };

  const handleDemoSuccessModalClose = () => {
    setDemoSuccessModalOpen(false);
  };

  const handleInvitationMessageChange = (event) => {
    setInvitationMessage(event.target.value);
  };



  const remainingChars = 500 - invitationMessage.length;
  return (
    <Box bgcolor={'#ffffff'}>
      {/* hero area start */}
      <Box>
        <Container maxWidth="lg">
          <Grid
            container
            minHeight="50vh"
            alignItems="center"
            pt={{ xs: 4, md: 5 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              textAlign={{ xs: "center", md: "start" }}
              px={{ xs: 2 }}
            >
              {/* <Typography
                mb={2}
                // className={classes.heroTitle}
                fontFamily={"inter"}
                fontSize={isMobile ? "560px" : "56px"}
                fontWeight={"bold"}
                color={"#121212"}
              >
                {"The Mortgage"} <br /> {"Specialist"}
              </Typography> */}
              <Typography
                mb={2}
                fontFamily="inter"
                style={{ fontSize: isMobile ? "36px" : "56px" }}
                fontWeight="bold"
                color={isMobile ? "#121212" : "#121212"}
              >
                {"The Mortgage"} <br /> {"Specialist"}
              </Typography>

              <Typography variant="body1" mb={5}>
                {`Home is not a place ..it's a feeling and we know it better than
                anyone.That is why we are here to help you finance your dream
                home with the help of our product and services.`}
              </Typography>
              <Button
                onClick={() => {
                  router("/signup");
                }}
                variant="contained"
              >
                Sign Up
              </Button>
            </Grid>

            {/* Intro Image */}
            <Grid item xs={12} md={6}>
              <Box my={{ md: "none", xs: 5 }}>
                <img src={heroImage} width={"100%"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* MLO Banner*/}
      <Box
        style={{
          backgroundImage: `url(${MloBannerNew})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '80vh',
          // minHeight: '100vh'
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            minHeight="70vh"
            alignItems="flex-start"
            justifyContent="flex-end"
            pt={{ xs: 4, md: 7 }}
          >
            <Grid
              item
              xs={12}
              md={8}
              textAlign={{ xs: "center", md: "end" }}
              px={{ xs: 2 }}

            >
              <Typography
                variant="h1"
                // component="h1"
                mb={2}
                fontWeight="bold"
                lineHeight="50px"
                padding={2}

                sx={{ backgroundColor: 'white', display: 'inline-block' }}
              >
                {`Newly Certified MLO?`}
              </Typography>

              <Typography variant="h1" lineHeight={'53.25px'} mb={5} sx={{ color: 'white' }}>
                Experience a Free Trial of the Future in Mortgage Processing!

              </Typography>


              <Button
                onClick={() => {
                  window.open(APIConfig.BASE_URL + "/signup");
                }}
                sx={{
                  width: '30%',
                  border: '2px solid white',
                  '&:hover': {
                    border: '2px solid white',
                  }
                }}
              >
                <Typography sx={{ color: 'white' }}>Explore Now</Typography>
              </Button>

            </Grid>

            {/* Intro Image */}
            {/* <Grid item xs={12} md={6}>
              <Box my={{ md: "none", xs: 5 }}>
                <img src={MLOBanner2} width={"100%"} />
              </Box>
            </Grid> */}
          </Grid>
        </Container>
      </Box>


















      {/* apply for loan area start */}
      <Box className={classes.bodyBgColor}>
        <Container maxWidth="lg">
          <Grid container alignItems="center" marginTop={5}>
            <Grid
              item
              xs={12}
              md={6}
              textAlign={{ xs: "center", md: "start" }}
              pr={{ md: 10, xs: 0 }}
            >
              <Typography
                variant="h2"
                component="h1"
                mb={2}
                fontWeight={"bold"}
                lineHeight={isMobile ? "35px" : "50px"}
              >
                {`Do you get tired of filling forms? Thats alright, `}
                <Typography
                  variant="h2"
                  component="span"
                  fontWeight="bold"
                  color="primary"
                >
                  Applying for loan just got easy with Kramasoft!
                </Typography>
              </Typography>
              <Typography variant="body1" mb={2} textAlign={"justify"}>
                {`The world of mortgages can be complex and we're here to help
                break it down every step of the way and help with our simple
                online application`}
                <Typography
                  component={"span"}
                  color={"#2F80ED"}
                  marginLeft={"4px"}
                >
                  {` - CliQloan.`}
                </Typography>
              </Typography>
              <Typography variant="body1" textAlign={"justify"}>
                {`CliQloan addresses the inefficiencies in the mortgage ecosystem
                effectively by leveraging technology to streamline the
                end-to-end process. Our secure & reliable platform will be with
                you from the beginning till loan closure. Our AI-powered
                solution gathers documents, validates them, and ensures a lower
                loan origination cycle.`}
              </Typography>
            </Grid>

            <Grid item container xs={12} md={6} my={5} columnSpacing={3}>
              <Grid
                item
                container
                direction={"column"}
                rowSpacing={3}
                md={6}
                mt={3}
              >
                <Grid item>
                  <Box
                    borderRadius={{ xs: "5px", md: "100px 0px 0px 0px" }}
                    className={classes.cardHover}
                  >
                    <img src={KsIcon} />
                    <Typography variant="h6" fontWeight={"bold"} my={1}>
                      {`Easy to use with intuitive interface`}
                    </Typography>
                    <Typography variant="body2">
                      {`Buying a home shouldn't be so painful-our online
                      application is simple & easy to use. We have dedicated
                      Mortgage Loan Officers, a help desk, and much more to
                      guide you through the process from start to finish.`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    borderRadius={{ xs: "5px", md: "0px 0px 0px 100px" }}
                    className={classes.cardHover}
                  >
                    <img src={KsIcon} />
                    <Typography variant="h6" fontWeight={"bold"} my={1}>
                      {`AI-powered engine`}
                    </Typography>
                    <Typography variant="body2">
                      {`Our AI-powered product helps you to cut down significant
                      efforts in documentation, and best of all ensures your
                      documents are free from any risk of errors, fraud, or
                      non-compliance.`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction={"column"}
                columnSpacing={1}
                rowSpacing={3}
                md={6}
                mt={{ xs: 1, md: "none" }}
              >
                <Grid item>
                  <Box
                    borderRadius={{ xs: "5px", md: "0px 100px 0px 0px" }}
                    className={classes.cardHover}
                  >
                    <img src={KsIcon} />
                    <Typography variant="h6" fontWeight={"bold"} my={1}>
                      {`Digital champion`}
                    </Typography>
                    <Typography variant="body2">
                      {`Digitizing the process from end to end to close allows us
                      to advocate for transparency on your behalf. Leverage
                      digital capabilities to reduce paperwork`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    borderRadius={{ xs: "5px", md: "0px 0px 100px 0px" }}
                    className={classes.cardHover}
                  >
                    <img src={KsIcon} />
                    <Typography variant="h6" fontWeight={"bold"} my={1}>
                      {`Secure Document management`}
                    </Typography>
                    <Typography variant="body2">
                      {`Store all your documents in one location and retrieve them
                      whenever needed. CliQloan's secure document management
                      solution ensures the complete security of the documents.`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        style={{
          backgroundImage: `url(${mloBanner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          //  minHeight: '100vh'
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            minHeight="50vh"
            alignItems="center"
            pt={{ xs: 4, md: 5 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              textAlign={{ xs: "center", md: "start" }}
              px={{ xs: 2 }}
            >
              <Typography
                variant="h2"
                component="h1"
                mb={2}
                fontWeight={"bold"}
                lineHeight={"50px"}
              >
                {`Looking for a trusted Mortgage Loan Officer in your state? `}
              </Typography>
              <Typography variant="body1" mb={5}>
                {`Our platform connects you with experienced professionals who understand the local market intricacies. 
                Whether you're buying your first home or refinancing, find the perfect MLO match 
                to guide you through the process seamlessly.`}
              </Typography>
              <Button
                onClick={() => {
                  // router("/"); //
                  window.open(APIConfig.BASE_URL + "/borrower-portal");

                }}
                variant="contained"
              >
                Borrower Portal
              </Button>
            </Grid>

            {/* Intro Image */}
            <Grid item xs={12} md={6}>
              <Box my={{ md: "none", xs: 5 }}>
                <img src={MLOBanner2} width={"100%"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>


      {/* product area start */}
      <Box>
        <Container maxWidth="lg">
          <Grid
            container
            minHeight="50vh"
            alignItems="center"
            pt={{ xs: 4, md: 5 }}
          >
            <Grid item xs={12} md={6}>
              <Box my={{ md: "none", xs: 5 }}>
                <img src={ProductBg} width={"100%"} />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              textAlign={{ xs: "center", md: "start" }}
              // px={{ xs: 2 }}
              p={{ xs: 2, md: 6 }}
            >
              <Typography mb={2} variant={"h3"} fontWeight={"bold"} lineHeight={"40px"} textAlign={"right"}>
                {`Experience the state-of-art mortgage application with new age
                digital genie for Mortgage universe -`}
                <Typography
                  variant="h3"
                  component={"span"}
                  color="primary"
                  marginLeft={"3px"}
                >
                  CliQloan
                </Typography>
              </Typography>
              <Typography variant="body1" mb={5} textAlign={"justify"}>
                {`CliQloan offers a best-in-class application experience, as well
                as a strong set of automation, customization, and branding
                capabilities. Our Motto is to give customers more than what they
                deserve. Good communication is essential for a great borrowing
                experience. We have a plethora of readily configurable and
                automatic communication techniques that ensures all customers &
                other stakeholders receive the information they require, when
                and wherever they require it.`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* about us area start */}
      <Box className={classes.bodyBgColor} id="about-us">
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              md={12}
              textAlign="center"
              px={{ xs: 2 }}
              my={{ xs: 5, md: 10 }}
            >
              <Typography mb={2} variant={"h2"}>
                {`About Us`}
              </Typography>
              <Typography
                mb={5}
                textAlign={"justify"}
                fontFamily={"DMSans-Regular"}
                fontSize={"16px"}
                color={"#221F1F"}
              >
                {`Kramasoft is a leading mortgage expert in creating great
                platforms for mortgage loan officers, borrowers and lenders.
                With intuitive interface and state of art features and
                capabilities, our applications are more than just applications
                for our customers- they are genies for life! We reimagine the
                mortgage loan process so that our customers can realize their
                dreams.`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* solution area start */}
      <Box
        bgcolor={"#2F80ED"}
        borderRadius="100px 100px 0px 0px"
        className={classes.bgSolution}
        id="product"
        mt={5}
      >
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid item xs={12} md={12} textAlign="center" px={{ xs: 2 }}>
              <Box my={7}>
                <Typography mb={2} variant={"h2"} color="white">
                  {`See our`}
                </Typography>
                <Typography mb={2} variant={"h2"} color="white">
                  Digital Solution
                </Typography>
                <Button
                  onClick={handleScheduleModalOpen}
                  variant="contained"
                  size="large"
                  className={classes.demoButton}
                >
                  {`Schedule a Demo`}
                </Button>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"flex-end"}
              >
                <img src={ScreenShot} width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Schedule demo modal */}

      <DialogCommonHooks
        title={"Schedule a demo"}
        show={openScheduleModal}
        handleClose={handleScheduleModalClose}
        body={
          <>
            <Box>
              <CustomInput
                size="small"
                fullWidth
                boxProps={{ my: 2 }}
                label={"First Name"}
                required={true}
                placeholder={"Jacob"}
              />
            </Box>
            <Box>
              <CustomInput
                size="small"
                fullWidth
                boxProps={{ my: 2 }}
                label={"Last Name"}
                required={true}
                placeholder={"Lee"}
              />
            </Box>
            <Box>
              <CustomInput
                size="small"
                fullWidth
                boxProps={{ my: 2 }}
                label={"Email id"}
                required={true}
                placeholder={"Jacob@gmail.com"}
              />
            </Box>
            <Box>
              <CustomInputPhone
                size="small"
                fullWidth
                boxProps={{ my: 2 }}
                label={"Phone Number"}
                required={true}
                placeholder={"(466)788-9807"}
                sicon={<LocalPhoneIcon />}
              />
            </Box>
            <Box>
              <div>
                <InputLabel>Message</InputLabel>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Enter "
                  multiline
                  rows={5}
                  rowsMax={7}
                  value={invitationMessage}
                  onChange={handleInvitationMessageChange}

                />
                <span>{remainingChars} characters remaining</span>
              </div>
            </Box>
            <Stack
              my={2}
              direction={"row"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button variant="outlined" onClick={handleScheduleModalClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleDemoSuccessModalOpen}>
                Send
              </Button>
            </Stack>
          </>
        }
      />

      <DialogCommonHooks
        title={"Schedule a demo"}
        show={openScheduleModal}
        handleClose={handleScheduleModalClose}
        body={
          <DemoScheduleFormWithMsg

          />
        }
      />

      {/* Demo Sucess Modal */}
      <DialogCommonHooks
        title={"Successfull"}
        show={openDemoSuccessModal}
        handleClose={handleDemoSuccessModalClose}
        body={
          <>
            <Box textAlign={"center"} my={3}>
              <img src={SucessDemoImage} width={"50%"} />
            </Box>
            <Box textAlign={"center"} margin={"auto"} p={3}>
              <Typography variant="h3">
                Thank you! <br />
                Your request for a demo has been sent.
              </Typography>
            </Box>
            <Stack my={3} direction={"row"} justifyContent={"center"}>
              <Button variant="contained" onClick={handleDemoSuccessModalClose}>
                Done
              </Button>
            </Stack>
          </>
        }
      />
    </Box>
  );
}

export default LandingMainBody;
