import { docDownload, docDownloadWithToken, docPreview, docPreviewWithToken, documentPreview } from "../../helpers/file-upload-download";
import { API } from "../../services/api-config";



// Borrower details

export const handleFileDownloadSupportingDocBorrower = (row, accessCode, taskCode) => {

    if (row?.docType === 'LocalUpload') {


        const storageKey = row?.storageKey;
        const fileName = row?.fileName;
        const apiUrl = API.proxyDocumentDownload
        const token = accessCode
        const docId = row?.docId

        const data = {

            storageKey: storageKey,
            //fileName: fileName,
            id: docId,// in case local upload 
            thirdPartyTaskCode: taskCode
        }

        if (storageKey && fileName) {

            docDownload(apiUrl, data, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        }

    } else {



        const storageKey = row?.storageKey;
        const fileName = row?.fileName;
        const apiUrl = API.downloadDoc
        const token = accessCode
        //const docId = row?.docId

        const data = {

            storageKey: storageKey,
            fileName: fileName,
            // fileName: docId,// in case local upload 
            thirdPartyTaskCode: taskCode
        }

        if (storageKey && fileName) {

            docDownload(apiUrl, data, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        }
    }
};























// Third Party

export const handleFileDownloadSupportingDoc = (row, accessCode, taskCode) => {

    if (row?.docType === 'LocalUpload') {


        const storageKey = row?.storageKey;
        const fileName = row?.fileName;
        const apiUrl = API.proxyDocumentDownload
        const token = accessCode
        const docId = row?.docId

        const data = {

            storageKey: storageKey,
            //fileName: fileName,
            id: docId,// in case local upload 
            thirdPartyTaskCode: taskCode
        }

        if (storageKey && fileName) {

            docDownloadWithToken(apiUrl, data, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        }

    } else {



        const storageKey = row?.storageKey;
        const fileName = row?.fileName;
        const apiUrl = API.downloadDoc
        const token = accessCode
        //const docId = row?.docId

        const data = {

            storageKey: storageKey,
            fileName: fileName,
            // fileName: docId,// in case local upload 
            thirdPartyTaskCode: taskCode
        }

        if (storageKey && fileName) {

            docDownload(apiUrl, data, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        }
    }
};




export const handleFileDownloadRequiredDoc = (row, accessCode, taskCode) => {

    if (row?.docType === 'LocalUpload') {


        const storageKey = row?.storageKey;
        const fileName = row?.fileName;
        const apiUrl = API.downloadDocResolve
        const token = accessCode
        const docId = row?.docId

        const data = {

            storageKey: storageKey,
            //fileName: fileName,
            fileName: docId,// in case local upload 
            thirdPartyTaskCode: taskCode
        }

        if (storageKey && fileName) {
            // Call docPreview to download or preview the document
            docDownloadWithToken(apiUrl, data, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        }

    } else {



        const storageKey = row?.storageKey;
        const fileName = row?.fileName;
        const apiUrl = API.downloadDocResolve
        const token = accessCode
        //const docId = row?.docId

        const data = {

            storageKey: storageKey,
            fileName: fileName,
            // fileName: docId,// in case local upload 
            thirdPartyTaskCode: taskCode
        }

        if (storageKey && fileName) {
            // Call docPreview to download or preview the document
            docDownloadWithToken(apiUrl, data, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        }
    }
};



export const previewDocumentAfterResolve = (item) => {


    if (item?.resolvedDocId) {
        documentPreview(API.proxyDocumentDownload, {
            id: item?.resolvedDocId,
            serviceType: "Loan",
        });
    }

}


export const downloadDocumentAfterResolve = (item) => {

    const data = {
        fileName: item?.resolvedDocFileName
    }

    docDownload(API.proxyDocumentDownload, {
        id: item?.resolvedDocId,
        serviceType: "Loan",
        data: data
    });

}
