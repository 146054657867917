import React, { useState, useEffect } from "react";
import useInput from "../../../helpers/use-input"
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, IconButton, } from "@mui/material";
import CustomInput from "../../../helpers/custom-input";
import { useFormik } from "formik";
import { useRecoilState, useRecoilValue } from "recoil";
import { globalSpinner, profileState } from "../../../recoil/profile-atom";
import { CREDCO_INFO, ROLE_LIST, UPDATE } from "../../../constants/constatnt-variable";
import { postApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import RadioButtonGroup from "../../../helpers/radio-button-group";

export default function CredcoCredentialsForm({ data, setCredcoEditSection, setData, setHideStatus, setValidationReset, setRoleVal, refreshData
}) {

    const [showPassword, setShowPassword] = useState(false);
    let profileInfo = useRecoilValue(profileState);
    let [spinner, setSpinner] = useRecoilState(globalSpinner);

    let [lastUsed, setLastUsed] = useState(null)



    useEffect(() => {

        setLastUsed(data?.isUsingBrokerInfo !== null ? (data.isUsingBrokerInfo ? "use broker info" : "my info") : null)


    }, [])


    const [credcoInfo] = useInput({
        data: data?.isUsingBrokerInfo !== null ? (data.isUsingBrokerInfo ? "use broker info" : "my info") : "use broker info",
        status: false,
        initial: null
    });

    const handleRadioButtonChange = (value) => {
        credcoInfo.saveValue(value);
    };

    useEffect(() => {

        if (data.isUsingBrokerInfo === null || data.isUsingBrokerInfo === true) {
            credcoInfo.saveValue("use broker info");
        } else {
            credcoInfo.saveValue("my info");
        }
    }, [data.isUsingBrokerInfo]);

    // const [credcoInfo] = useInput({
    //     data: null,
    //     status: false,
    //     initial: null

    // })

    const [internalAccountIdentifier] = useInput({
        data: data?.internalAccountIdentifier,
        status: true,
        initial: ""

    })
    const [requestedByName] = useInput({
        data: data?.requestedByName,
        status: true,
        initial: ""

    })
    const [requestingPartyBranchIdentifier] = useInput({
        data: data?.requestingPartyBranchIdentifier,
        status: true,
        initial: ""

    })
    const [loginAccountIdentifier] = useInput({
        data: data?.loginAccountIdentifier,
        status: true,
        initial: ""

    })
    const [loginAccountPassword] = useInput({
        data: data?.loginAccountPassword,
        status: true,
        initial: ""

    })


    const handleValidation = (values) => {
        let errors = {};
        if (credcoInfo.value === "my info" || (profileInfo?.role == ROLE_LIST.mloOwner || profileInfo?.role == ROLE_LIST.broker)) {
            if (internalAccountIdentifier.value === "" || internalAccountIdentifier.value === null) {
                errors.internalAccountIdentifier = "Enter Internal Account Identifier to proceed";
            }
            if (requestedByName.value === "" || requestedByName.value === null) {
                errors.requestedByName = "Enter Requested By Name to proceed";
            }

            if (requestingPartyBranchIdentifier.value === "" || requestingPartyBranchIdentifier.value === null) {
                errors.requestingPartyBranchIdentifier = "Enter Requesting Party Branch Identifier to proceed";
            }
            if (loginAccountIdentifier.value === "" || loginAccountIdentifier.value === null) {
                errors.loginAccountIdentifier = "Enter Login Account Identifier to proceed";
            }

            if (loginAccountPassword.value === "" || loginAccountPassword.value === null) {
                errors.loginAccountPassword = "Enter Login Account Password to proceed";
            }
        }
        return errors;
    };


    const formik = useFormik({
        initialValues: {
            internalAccountIdentifier: internalAccountIdentifier.value,
            requestedByName: requestedByName.value,
            requestingPartyBranchIdentifier: requestingPartyBranchIdentifier.value,
            loginAccountIdentifier: loginAccountIdentifier.value,
            loginAccountPassword: loginAccountPassword.value,
        },
        validate: (values) => handleValidation(values),
        onSubmit: (values) => {
            handleSubmit();
        },
    });


    const updateUi = (res) => {
        if (res && (res.status === 200 || res.status === 201)) {

            setCredcoEditSection(false)
            setData(res.data)
            setSpinner(false)
        }

    }
    const handleSubmit = () => {
        setSpinner(true)

        let apiCallData = {
            internalAccountIdentifier: internalAccountIdentifier.value,
            requestedByName: requestedByName.value,
            requestingPartyBranchIdentifier: requestingPartyBranchIdentifier.value,
            loginAccountIdentifier: loginAccountIdentifier.value,
            loginAccountPassword: loginAccountPassword.value,
            isUsingBrokerInfo: (profileInfo?.role == ROLE_LIST.mloOwner || profileInfo?.role == ROLE_LIST.broker) ? false : credcoInfo.value === "use broker info" ? true : false
            //isUsingBrokerInfo: credcoInfo.value === "use broker info" ? true : false
        }
        if (data?.internalAccountIdentifier) {
            postApiCallDynamic({
                refresh: (res) => {
                    return res;
                }, data: apiCallData, path: API.credcoCredentials, header: UPDATE
            }).then((res) => updateUi(res),
                setValidationReset(false)
            )
        } else {
            postApiCallDynamic({
                refresh: (res) => {
                    return res;
                }, data: apiCallData, path: API.credcoCredentials
            }).then((res) => updateUi(res),
                setValidationReset(true))

        }

        refreshData();

    }

    // useEffect(() => {
    //     if (credcoInfo.value === "my info" && lastUsed === "my info") {
    //         setValidationReset(false)
    //     }
    //     else {
    //         setValidationReset(true)


    //     }
    // }, [lastUsed]);



    useEffect(() => {
        if (credcoInfo.value === "my info" && lastUsed === "my info") {
            fetchInputFields()
            setValidationReset(false)
        }
        else {
            resetInputFields();
            setValidationReset(true)

        }
    }, [credcoInfo.value]);

    const resetInputFields = () => {

        internalAccountIdentifier.saveValue("")
        requestedByName.saveValue("")
        requestingPartyBranchIdentifier.saveValue("")
        loginAccountIdentifier.saveValue("")
        loginAccountPassword.saveValue("")
    };

    const fetchInputFields = () => {

        internalAccountIdentifier.saveValue(data?.internalAccountIdentifier)
        requestedByName.saveValue(data?.requestedByName)
        requestingPartyBranchIdentifier.saveValue(data?.requestingPartyBranchIdentifier)
        loginAccountIdentifier.saveValue(data?.loginAccountIdentifier)
        loginAccountPassword.saveValue(data?.loginAccountPassword)
    };







    useEffect(() => {

        const hideStatus = !(credcoInfo.value === "my info")
        setHideStatus(hideStatus)

    }, [credcoInfo.value]);


    useEffect(() => {

        setRoleVal(profileInfo?.role)

    }, [profileInfo?.role]);



    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                {profileInfo?.role == ROLE_LIST.mlo ?
                    <RadioButtonGroup
                        data={CREDCO_INFO}
                        state={credcoInfo}
                        defaultValue={data.isUsingBrokerInfo !== null ? (data.isUsingBrokerInfo ? "use broker info" : "my info") : "use broker info"}
                        direction={"row"}
                        onChange={(value) => handleRadioButtonChange(value)}

                    />

                    : null}


                {(credcoInfo.value === "my info" || (profileInfo?.role == ROLE_LIST.mloOwner || profileInfo?.role == ROLE_LIST.broker)) && (
                    <>
                        <CustomInput
                            required
                            boxProps={{ my: 2 }}
                            size="small"
                            fullWidth
                            label={"Internal Account Identifier"}

                            placeholder={"122"}
                            change={internalAccountIdentifier}
                            error={formik.errors.internalAccountIdentifier}
                        />
                        <CustomInput
                            required
                            boxProps={{ my: 2 }}
                            size="small"
                            fullWidth
                            label={"Requested By Company Name"}
                            placeholder={"Thomos Jone"}
                            change={requestedByName}
                            error={formik.errors.requestedByName}
                        />
                        <CustomInput
                            required
                            boxProps={{ my: 2 }}
                            size="small"
                            fullWidth
                            label={"Requesting Party Branch Identifier"}

                            placeholder={"34343"}
                            change={requestingPartyBranchIdentifier}
                            error={formik.errors.requestingPartyBranchIdentifier}
                        />
                        <CustomInput
                            required
                            boxProps={{ my: 2 }}
                            size="small"
                            fullWidth
                            label={"Login Account Identifier"}
                            placeholder={"4004107"}
                            change={loginAccountIdentifier}
                            error={formik.errors.loginAccountIdentifier}
                        />
                        <CustomInput
                            required
                            boxProps={{ my: 2 }}
                            size="small"
                            fullWidth
                            label={"Login Account Password"}

                            placeholder={"*******"}
                            type={showPassword ? "text" : "password"}
                            eicon={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setShowPassword(!showPassword);
                                    }}

                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            }
                            change={loginAccountPassword}
                            error={formik.errors.loginAccountPassword}
                        />
                    </>
                )}
            </Box>


            <Box mt={credcoInfo.value === "my info" || (profileInfo?.role == ROLE_LIST.mloOwner || profileInfo?.role == ROLE_LIST.broker) ? 0 : 66}>

                <Button type="submit" variant="contained" size="large" fullWidth>
                    Submit
                </Button>
            </Box>
        </form >
    );
}