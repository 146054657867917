import { Avatar, Box, Stack, Typography } from "@mui/material";
import AvaterMlo from "../../../../icons/avatar_mlo.png";
import AvaterMlp from "../../../../icons/avatar_mlp.png";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DialogCommonHooks } from "../../../../helpers/modal/modal";
import LoanSummaryMloMlpConfirmationForm from "../loan-summary-forms/loan-summary-mlomlp-confirmation-form";
import LoanSummaryMloMlpChangeForm from "../loan-summary-forms/loan-summary-mlomlp-change-form";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import { useRecoilState } from "recoil";
import { profileState } from "../../../../recoil/profile-atom";
import { KSMailIcon, KSPhoneIcon } from "../../../../icons/custome-icon";

// export default function LoanSummaryMloMlpProfile({
//   data,
//   type,
//   avatar,
//   userType,
//   mloMlpList,
//   loanCode,
//   reload,
// }) {
//   let [profileData, setProfileData] = useRecoilState(profileState);
//   const visiblity = true;

//   const [mloMlpChangeConfirmation, setMloMlpChangeConfirmation] =
//     useState(false);
//   const handleMloMlpChangeConfirmationOpen = () => {
//     setMloMlpChangeConfirmation(true);
//   };
//   const handleMloMlpChangeConfirmationClose = () => {
//     setMloMlpChangeConfirmation(false);
//   };

//   const [changeMloMlp, setChangeMloMlp] = useState(false);
//   const handleChangeMloMlplOpen = () => {
//     setChangeMloMlp(true);
//     setMloMlpChangeConfirmation(false);
//     userType(type);
//   };
//   const handleChangeMloMlplClose = () => {
//     setChangeMloMlp(false);
//     setMloMlpChangeConfirmation(false);
//   };
//   return (
//     <>
//       <Box>





//         <Stack direction={"column"} gap={2} alignItems={"flex-start"}>

//           {/* Avatar and Name Section */}
//           <Stack direction="row" alignItems="center" gap={2}>
//             <Avatar
//               variant="rounded"
//               src={avatar ? avatar : type === 'MLO' ? AvaterMlo : AvaterMlp}
//               sx={{
//                 width: 40,
//                 height: 40,
//               }}
//             />
//             <Typography variant="body1">
//               {data?.firstName || data?.lastName
//                 ? `${data?.firstName} ${data?.lastName}`
//                 : "Not assigned"}
//             </Typography>
//           </Stack>

//           {/* Designation Section */}
//           <Box ml={8}>
//             <Typography variant="body1">Designation: {type}</Typography>

//             {/* Phone Number Section */}
//             <Typography variant="body1">
//               <KSPhoneIcon />: {data?.phone || "Not available"}
//             </Typography>

//             {/* Email Section */}
//             <Typography variant="body1">
//               <Box component="span" display="inline-flex" alignItems="center" maxWidth="50%">
//                 <Box component="span" display="inline-flex" whiteSpace="nowrap">
//                   <KSMailIcon />: {data?.email || "Not available"}
//                 </Box>
//               </Box>
//             </Typography>
//           </Box>

//         </Stack>

//       </Box>


//       {/* Change MLO/MLP Modal */}


//     </>
//   );
// }
export default function LoanSummaryMloMlpProfile({
  data,
  type,
  avatar,
  userType,
  mloMlpList,
  loanCode,
  reload,
}) {
  let [profileData, setProfileData] = useRecoilState(profileState);
  const visiblity = true;

  const [mloMlpChangeConfirmation, setMloMlpChangeConfirmation] = useState(false);
  const handleMloMlpChangeConfirmationOpen = () => {
    setMloMlpChangeConfirmation(true);
  };
  const handleMloMlpChangeConfirmationClose = () => {
    setMloMlpChangeConfirmation(false);
  };

  const [changeMloMlp, setChangeMloMlp] = useState(false);
  const handleChangeMloMlplOpen = () => {
    setChangeMloMlp(true);
    setMloMlpChangeConfirmation(false);
    userType(type);
  };
  const handleChangeMloMlplClose = () => {
    setChangeMloMlp(false);
    setMloMlpChangeConfirmation(false);
  };

  return (
    <>
      <Box>
        {/* Avatar and Name Section */}
        <Stack direction="column" gap={1} alignItems="flex-start">
          <Stack direction="row" alignItems="center" gap={2}>
            <Avatar
              variant="rounded"
              src={avatar ? avatar : type === 'MLO' ? AvaterMlo : AvaterMlp}
              sx={{
                width: 40,
                height: 40,
              }}
            />
            <Typography variant="body1">
              {data?.firstName || data?.lastName
                ? `${data?.firstName} ${data?.lastName}`
                : "Not assigned"}
            </Typography>
          </Stack>

          {/* Designation, Phone, and Email Section with alignment starting from the avatar's left */}
          <Stack direction="column" spacing={0} sx={{ marginLeft: 7 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Designation: {type}
            </Typography>

            <Typography variant="body1">
              {/* <KSPhoneIcon />: {data?.phone } */}
              Contact: {data?.phone}
            </Typography>
            <Typography variant="body1">
              <Box component="span" display="inline-flex" alignItems="center" mb={2}>
                {/* <KSMailIcon />: {data?.email } */}
                Email: {data?.email}
              </Box>
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
