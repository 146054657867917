
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from "@mui/styles";
import { Paper, Grid, Stack, Typography, Divider, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@mui/material";
import axios from 'axios';
import Logo from "../../../assets/Cliqloan-logo 1.png"
import { BillContext } from "./BillContext";
import Invoice from './invoice';
import { API } from '../../../services/api-config'






function CurrentInvoice({ userCode }) {
  //const classes = useStyles();

  const { isNewUser } = useContext(BillContext);

  //let userCode;
  let isMounted = true;

  const [loading, setLoading] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [cItem, setCItem] = useState({});  // json inside json
  const [disItem, setDisItem] = useState({});
  const [invoiceItem, setInvoiceItem] = useState([]);  // array inside json

  const [invoiceNumber, setInvoiceNumber] = useState(null);



  useEffect(() => {


    // const BASE_URL = "https://stage.kramasoft.com/ks-bill/api/v1/invoice"
    const BASE_URL = API.billInvoice
    const INVOICE_URL = "current-month-invoice?userCode=";
    //const CURRENT_INVOICE_ID = "52fqo9bknq_1676555997907";  // With Coupon
    const CURRENT_INVOICE_ID = userCode;  // With Coupon
    const INVOICE_API_URL = `${BASE_URL}/${INVOICE_URL}${CURRENT_INVOICE_ID}`;


    //Final
    axios
      .get(INVOICE_API_URL)
      .then((response) => {
        if (isMounted) {
          setInvoiceDetails(response.data);
          setCItem(response.data.cItem);
          setDisItem(response.data.disItem)
          setInvoiceItem(response.data.invoiceItem);
          setInvoiceNumber(response.data.invoiceNumber);
          setLoading(false);

        }

      })
      .catch((error) => {
        if (isMounted) {
          console.error("Error fetching Invoice Details:", error);
          setLoading(false);
        }
      });
  }, []);

  const handleDownloadPDF = () => {
    if (invoiceNumber) {
      const PDF_GENERATE_URL = `${API.downloadInvoice}/generatePdf?invoiceNumber=${invoiceNumber}`;
      axios.get(PDF_GENERATE_URL, { responseType: 'blob' })
        .then((response) => {
          // Create a Blob from the PDF data received
          const blob = new Blob([response.data], { type: 'application/pdf' });
          // Create a link element to download the PDF
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          // Cleanup
          document.body.removeChild(link);
        })
        .catch((error) => {
          // Handle error in PDF generation
          console.error("Error generating PDF:", error);
        });
    }
  };




  if (invoiceNumber === null || typeof invoiceNumber === 'undefined') {
    return (
      <Box textAlign="center">
        <Typography variant="h5">Invoice will be generated on the 1st of each month. Please check back accordingly for updates.</Typography>
      </Box>
    );
  }

  return (

    invoiceNumber !== null ? (


      <>
        {/* <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}> */}

        <Invoice invoiceDetails={invoiceDetails} />


        <Box marginTop={3} style={{ textAlign: 'right' }}>

          <Button
            variant="contained"
            color="primary"
            //className={classes.downloadButton}
            onClick={handleDownloadPDF}
          >
            Download
          </Button>
        </Box>
        {/* </Stack> */}
      </>) : ""


  );
}



export default CurrentInvoice;
