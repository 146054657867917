import { CheckCircleRounded, Lock } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  KSChatSmIcon,
  KSMailIcon,
  KSPhoneIcon,
  KSUserGroupIcon,
} from "../../../../icons/custome-icon";
import AvaterMlo from "../../../../assets/Avatar-mlo.png";
import {
  TimeStampToMonthDateYear,
  TimeStampToMonthDateYearTime,
} from "../../../../helpers/time-formater";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import { Path } from "../../../../routes/route-config";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../../recoil/profile-atom";
import { ROLE_LIST } from "../../../../constants/constatnt-variable";
import { getImageUrl } from "../../../../helpers/file-upload-download";
import ChatApp from "../../../../pages/chat/chat-app";
import { kstheme } from "../../../../theme/theme";

export default function LoanSummaryBorrowerInformation({
  loanState = "",
  loanStateDate = null,
  lockStatus = null,
  lockExpirationDate = null,
  borrowerData,
}) {
  const { visiblity } = useContext(LoanSummaryParentData);
  let profileInfo = useRecoilValue(profileState);

  const location = useLocation();
  const route = useNavigate();
  const [chatData, setChatData] = useState(
    {
      firstScreen: false,
      externalChat: {},
      internalChat: {},
      appNo: ""
    }
  );
  const goToAdditionalLoanContact = () => {
    route(location.pathname.replace(/\/[^/]+\/[^/]+$/, `/${borrowerData?.formId}/additional-loan-contact`))
  }
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

  return (
    <>
      <Grid container>
        <Grid item p={1} xs={12} md={"auto"}>
          <Avatar
            style={(profileInfo.role !== ROLE_LIST.borrower && !isMobile) ? { marginRight: '32px' } : {}}
            variant="rounded"
            src={getImageUrl(borrowerData?.avatarDocId, borrowerData?.avatarDocFileName, 'avatar')}
            sx={{
              width: { md: 100, sm: 80, xs: 70 },
              height: { md: 100, sm: 80, xs: 70 },
              margin: { xs: "auto" },
            }}
          />
        </Grid>
        <Grid
          container
          item
          p={1}
          xs={12}
          md={7}
          mt={0.5}
          ml={profileInfo.role == ROLE_LIST.borrower ? 3 : null}
          flexGrow={1}
          spacing={1}
          direction={profileInfo.role == ROLE_LIST.borrower ? (isMobile ? "row" : "column") : "row"}
          justifyContent={isMobile ? 'center' : loanState && loanStateDate ? "flex-start" : (!isMobile ? 'flex-start' : 'center')}
          alignItems={profileInfo.role == ROLE_LIST.borrower ? "self-start" : 'center'}
        >
          <Grid direction={isMobile ? 'column' : ''}>
            <Grid item xs={12}>
              {/* Borrower Name and Form Link Start */}
              <Stack
                direction={"row"}
                gap={1}
                alignItems={"center"}
                justifyContent={{ xs: "center", md: "initial" }}
              >
                <Typography variant="h2">{`${borrowerData?.firstName} ${borrowerData?.lastName}`}</Typography>
                {visiblity ? (
                  <Link
                    target="_blank"
                    to={
                      `../../` + Path.loanApplication + `/${borrowerData.formId}`
                    }
                    color="primary"
                  >{`Edit Loan`}</Link>
                ) : null}
              </Stack>
              {/* Borrower Name and Form Link End */}
            </Grid>
            {loanState && loanStateDate ? (
              <Grid
                item
                minWidth={'100%'}
                xs={6}
                borderRight={2}
                pr={{ xs: 1, md: 0 }}
                borderColor={"#C4C4C420"}
              >
                {/* Borrower Info Start */}

                {loanState ? (
                  <Stack
                    color={"success.main"}
                    direction={"row"}
                    gap={1}
                    alignItems={{ xs: "initial", md: "center" }}
                    my={1}
                    justifyContent={isMobile ? "center" : ""}
                  >
                    <Typography variant="body2">{`${loanState}`}</Typography>
                    <CheckCircleRounded sx={{ fontSize: "14px" }} />
                  </Stack>
                ) : null}
                {loanStateDate ? (
                  <Typography
                    my={1}
                    variant="overline"
                    fontStyle={"italic"}
                    sx={{ textTransform: "initial", lineHeight: 1 }}
                    display={isMobile ? 'flex' : ''}
                    justifyContent={isMobile ? 'center' : ''}
                  >
                    {`(${TimeStampToMonthDateYearTime(loanStateDate)})`}
                  </Typography>
                ) : null}

                {/* Borrower Info End */}
              </Grid>
            ) : null}
          </Grid>
          {lockStatus == "Locked" || lockExpirationDate &&
            <Grid item xs={6}>
              {/* Lock Status Start */}
              <Stack
                direction={"column"}
                gap={1}
                my={{ xs: 2, md: 0 }}
                alignItems={"start"}
                pl={{ xs: 0, md: 3 }}
              >
                {lockStatus == "Locked" ? (
                  <Chip
                    icon={<Lock />}
                    label="LOCKED"
                    variant="outlined"
                    color="warning"
                    sx={{
                      backgroundColor: "warninglight.main",
                      border: "none",
                      fontWeight: "bold",
                      letterSpacing: 1.5,
                    }}
                  />
                ) : null}
                {lockExpirationDate ? (
                  <Box>
                    <Typography
                      variant="overline"
                      fontStyle={"italic"}
                      sx={{ textTransform: "initial", lineHeight: 1 }}
                    >
                      {`(Expires on ${TimeStampToMonthDateYear(
                        lockExpirationDate
                      )})`}
                    </Typography>
                  </Box>
                ) : null}
              </Stack>
              {/* Lock Status End */}
            </Grid>}
          <Grid item xs={12} style={(profileInfo.role === 'Borrower' && !isMobile) ? { position: 'absolute', right: '100px' } : {}}>
            {/* Communication Icons Start */}
            {/* <Stack
              direction={profileInfo.role == ROLE_LIST.borrower ? "column" : "row"}
              justifyContent={{ xs: "center", md: "start" }}
              alignItems={profileInfo.role == ROLE_LIST.borrower ? "end" : ""}
            >
            
              <ChatApp setChatData={setChatData} chatData={{ chatData }} />
              {borrowerData?.phone ? (
                <IconButton href={`tel:${borrowerData?.phone}`}>
                  {profileInfo.role === 'Borrower' &&
                    <Typography variant="h6" pr={2}>
                      {borrowerData?.phone}
                    </Typography>
                  }
                  <KSPhoneIcon />
                </IconButton>
              ) : null}

              {borrowerData?.chat ? (
                <IconButton>
                  <KSChatSmIcon />
                </IconButton>
              ) : null}

              {borrowerData?.email ? (
                <IconButton href={`mailto:${borrowerData?.email}`}>
                  {profileInfo.role === 'Borrower' &&
                    <Typography variant="h6" pr={2}>
                      {borrowerData?.email}
                    </Typography>}
                  <KSMailIcon />
                </IconButton>
              ) : null}

              {profileInfo.role == ROLE_LIST.borrower ? null : (
                <IconButton onClick={goToAdditionalLoanContact}>
                  <KSUserGroupIcon />
                </IconButton>
              )}
            </Stack> */}
            <Stack
              direction={profileInfo.role == ROLE_LIST.borrower ? "column" : "row"}
              justifyContent={{ xs: "center", md: "start" }}
              alignItems={isMobile ? profileInfo.role == ROLE_LIST.borrower ? "center" : "start" : profileInfo.role == ROLE_LIST.borrower ? "end" : "start"}

            >
              <ChatApp setChatData={setChatData} chatData={{ chatData }} />
              {borrowerData?.phone && (
                <IconButton href={`tel:${borrowerData?.phone}`}>
                  {profileInfo.role === 'Borrower' && (
                    <Typography variant="h6" pr={2}>
                      {borrowerData?.phone}
                    </Typography>
                  )}
                  <KSPhoneIcon />
                </IconButton>
              )}

              {borrowerData?.chat && (
                <IconButton>
                  <KSChatSmIcon />
                </IconButton>
              )}

              {borrowerData?.email && (
                <IconButton href={`mailto:${borrowerData?.email}`}>
                  {profileInfo.role === 'Borrower' && (
                    <Typography variant="h6" pr={2}>
                      {borrowerData?.email}
                    </Typography>
                  )}
                  <KSMailIcon />
                </IconButton>
              )}

              {profileInfo.role == ROLE_LIST.borrower ? null : (
                <IconButton onClick={goToAdditionalLoanContact}>
                  <KSUserGroupIcon />
                </IconButton>
              )}
            </Stack>

            {/* Communication Icons End */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
